// react imports
import React, { useState, useRef, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import { useSwipeable } from 'react-swipeable'
import 'react-multi-carousel/lib/styles.css';
import { FotovalogatasRespo } from '../data/fotovalogatas'
import { MDBContainer
          } from 'mdb-react-ui-kit';

const CarouselComponentRespo = (props) => {

  //MDBAlert
  const [starttime, setStarttime] = useState(false);
  const [onTouch, setOnTouch] = useState(false)


  // STATES
  const [screenWidth, setScreenWidth] = useState(window.innerWidth );


  const cardsRef  = useRef(null);
    

  const responsive = {
    small: {
        breakpoint: { max: 990, min: 0 },
        items: 1,
        partialVisibilityGutter: 1
    }
  };




  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

  useEffect(() => {
    setScreenWidth(window.innerWidth );
  }, []);

  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth );
  };

  const onTouchStart = () => {
    
    setStarttime(true)
  }
 
  const onTouchMove = (e) => {
    setStarttime(false)
  }

  const onTouchEnd = () => { 
    if(!starttime){
        setOnTouch(false)
          
    }else {
        setOnTouch(true)
       
    }
    
    
   }

   const handlers = useSwipeable({
    
    onSwipedLeft: () => {document.body.style.overflow = 'hidden'},
    onSwipedRight: () => {document.body.style.overflow = 'hidden'},
    onSwipedUp: () => {document.body.style.overflow = 'scroll'},
    onSwipedDown: () => {document.body.style.overflow = 'scroll'},
    onSwiping: () => {setStarttime(false)},
    onSwiped: () => {document.body.style.overflow = 'scroll'},
    onTap: () => {document.body.style.overflow = 'scroll'; setStarttime(true)}
   // onTouchStartOrOnMouseDown: () => console.log("swipe")
  });

  const refPassthrough = (el) => {
    handlers.ref(el);
    cardsRef.current = el;
  }

 

  return (
    <MDBContainer className="p-0" >
    <div className="galerie-component-respo" onTouchMove={onTouchMove} 
                                            onTouchStart={onTouchStart} 
                                            onTouchEnd={onTouchEnd} {...handlers} ref={refPassthrough}>
            <Carousel
                additionalTransfrom={0} 
                swipeable={true}
                showDots={false}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={false}
                slidesToSlide={1}
                centerMode={false}
                keyBoardControl={false}
                customTransition="transform 500ms linear"
                transitionDuration={500}
                containerClass="carousel-container"
                autoPlay={false}
                autoPlaySpeed={18500}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                removeArrowOnDeviceType={["small"]}
               
            >
  
                {FotovalogatasRespo.respoImages.map((k,n) => {
                    return( 
                    <div  key={n+500} className="d-flex align-items-center" style={{ width: "100%"}}>
                        <div className="foto-block-respo" style={{ width: "100%"}}>
                            <div className="foto2">
                                <img src={k} className="gallery-4" alt="" style={{objectFit: "cover"}} />
                            </div>
                        </div>
                    </div>
                    )
                    })}
            </Carousel>
           
    </div>
    </MDBContainer>

  );
};

export default CarouselComponentRespo;
