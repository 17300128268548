import React, { Component } from "react";
import { Navigate } from 'react-router';

// doCheck() is called every second
// proceedsIf() returns true if the component can proceed to next page
// nextPage is the page to go after this page
// children is diplayed before proceeding
class WaitingFor extends Component {
  componentDidMount() {
	  this.interval = setInterval(this.props.doCheck, 1000);
	}
	componentWillUnmount() {
	  clearInterval(this.interval);
	}

    render() {
        if (this.props.proceedsIf()) {
            return (
				      <Navigate to={this.props.nextPage}/>
			      );
        } else {
            return this.props.children;
        }
    }

}

export default WaitingFor;