import React, { Component } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavbarComponent from '../components/NavbarComponent';
import DesignerComponent from '../components/DesignerComponent';
import FooterModal from '../components/Modals/FooterModal';
import { withTranslation } from 'react-i18next';
import InterB2BAxios from '../Service/InterB2BAxios';
import myensign_icon from '../assets/media/images/MYENSIGN_LOGO_VECTOR_ kék.svg';
import backgroundImage from '../assets/media/images/csobbanas_2.png'
import backgroundImage_2 from '../assets/media/images/tervezo.png'
import touchto from '../assets/media/images/touchto.png'
import { clearBasket, addItemToBasket, getAllBasketItems } from '../components/db/basket';

import i18n from '../i18n';

import 'react-slideshow-image/dist/styles.css'

import CarouselComponentRespo from '../components/CarouselComponentRespo'
import CarouselComponent from '../components/CarouselComponent'
import CarouselComponentJewelleryRespo from '../components/CarouselComponentJewelleryRespo'
import CarouselComponentJewellery from '../components/CarouselComponentJewellery'

import{
	MDBContainer,
	MDBRow,
	MDBCol
} from 'mdb-react-ui-kit';



class MainPage extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
		const { t } = this.props;
    	this.state = {jewellerys : [],
									pagingstatus: true,
									site: 0,
									navbarPaddingTop: true,
									navbarPaddingBottom: false,
									jewellery: null,
									isDesigner: false,
									basicOpen: false,
									basicCollapse1: false,
									basicCollapse2: false,
									stirpeConfirmation: null,
									isBasket: true,
									isLogo: true,
									isShowingAlert: false,
									onDeleteComponent: false
									};
 	};

	componentDidMount() {
		let self = this;
		this.onGetAllJewellery(0);
		document.addEventListener("scroll", this.scrollEventListener);
		if(this.props.searchParams.get('tempbasketid') !== null){
			this.interAxios.get('/tempbasket/' + this.props.searchParams.get('tempbasketid'), function(response) {
				let item = response.data
				item.basketId = parseInt(self.props.searchParams.get('tempbasketid'));
				let items = [];
				getAllBasketItems().then((basketContent) => {
					let itemsFinally = items.concat(basketContent);
					console.log(itemsFinally)
					if (!itemsFinally.map(k => {return(k.basketId)}).includes(parseInt(self.props.searchParams.get('tempbasketid')))){
						addItemToBasket(item);
						setTimeout(() => {
							self.showBasketModal();
							}, 500)
					}
				});
			})	
		}
	}

	componentDidUpdate() {
		if(this.props.searchParams.get("stirpeConfirmation") !== this.state.stirpeConfirmation){
			const { t } = i18n;
			this.showModal(t('A_successful_shopping'));
			clearBasket();
			this.setState({stirpeConfirmation: this.props.searchParams.get("stirpeConfirmation")})
		}
		if(this.state.isBodyScroll){
			document.body.style.overflow = 'hidden'

		}else{
			document.body.style.overflow = 'scroll'
		}

	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.scrollEventListener);
	}

	onGetAllJewellery = (pageIndex) => {
		let self = this;
			let container = document.getElementById('productpage');
			let animationDuration = 300; // in milliseconds
			if(container !== null){
				container.classList.add('alert-hidden');
			}

		   setTimeout(() => this.interAxios.get('/catalog', {params: {pageIndex: pageIndex, pageSize: 34}}, function(response) {
			   self.setState({	jewellerys: response.data.content.filter(k => k.picture !== null && k.price !== 2),
							   totalElements: response.data.totalElements,
							   pageIndex: response.data.pageIndex,
							   numberOfPages: response.data.numberOfPages,
							   isShowingAlert: true
						   })
			   if(container !== null){
				setTimeout(() =>{
					container.classList.remove('alert-hidden');
					//container.classList.add('alert-shown');
				}, animationDuration)
			   }
		   }), animationDuration);
	}

	showModal = (text) => {
		this.setState({showModal: true, text: text, isBodyScroll: true})
		//document.body.style.overflowY = "hidden";
	}

	closeModal = (text) => {

		this.setState({onDeleteComponent: false, showModal: false, stirpeConfirmation: null, isBodyScroll: false})
		//this.props.navigate('/test')
		this.props.navigate('/')
	}

	showBasketModal = () => {
		console.log("belépek")
		this.setState({isDesigner: false, onDeleteComponent: true, showModal: true, text: i18n.t('Basket'), isBodyScroll: true})
	}

	onDesignerComponent = (jewellery) => {
		this.setState({isDesigner: true, onDeleteComponent: false, jewellery: jewellery});
	}

	onCancelDesignerComponent = () => {
		this.setState({isDesigner: false});
	}

	setBasicOpen = (e) => {
		this.setState({basicOpen: !this.state.basicOpen})
	}

	setBasicCollapse1 = (e) => {
		this.setState({basicCollapse1: !this.state.basicCollapse1})
	}

	setBasicCollapse2 = (e) => {
		this.setState({basicCollapse2: !this.state.basicCollapse2})
	}


	scrollEventListener = (event) => {
		let height_1 = document.getElementById("section_1").offsetHeight- 100;
		let height_2 = document.getElementById("section_1").offsetHeight + document.getElementById("section_2").offsetHeight ;
		let height_3 = document.getElementById("section_1").offsetHeight + document.getElementById("section_2").offsetHeight + document.getElementById("section_3").offsetHeight;
		let height_4 = document.getElementById("section_1").offsetHeight + document.getElementById("section_2").offsetHeight + 2*document.getElementById("section_3").offsetHeight ;

		const section_1 = window.scrollY < height_1 ? true : false;
		const section_2 = window.scrollY < height_4 ? true : false;
		const section_3 = height_4 <= window.scrollY ? true : false;
		this.setState({ isLogo: section_1, isBasket: section_2,  isNavbarTop: section_3});
	}

	onScrollToDesign = () => {
		setTimeout(() => {
			let element = document.getElementById('section_3');
			let headerOffset =  59;
			let elementPosition = element.getBoundingClientRect()?.top;
			let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
			window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
			}, 1000)

	};


	render() {
		const { t } = i18n;
		console.log(this.state.onDeleteComponent)
	return (
		<>

				<div className="fixed-top">
					<NavbarComponent 	isLogo = {this.state.isLogo}
										isBasket = {this.state.isBasket}
										setBasicOpen = {this.setBasicOpen}
										showBasketModal = {this.showBasketModal}
										showModal ={this.showModal}
					/>
				</div>

			<div className="d-none d-lg-block">
				<section id="section_1" className="section main-home d-flex justify-content-center align-items-center" style={{marginTop: "75px"}}>

					<img src={backgroundImage} id="backgroundImage" className="backgroundImage-style"/>

				</section>
				<MDBRow center className="pt-5 m-0 ">
						<span className="tite-text">
						Myensign Yachting Jewels
						</span>
					</MDBRow>
				<section id="section_2" className="section main-fotoMontage">
					{/*<MDBContainer fluid className="myslide-container">
						<MySlide/>

					</MDBContainer>*/}


						<CarouselComponent/>


					{/*<MobileComponent/>*/}
				</section>

				<MDBContainer className="p-0" style={{maxWidth: "1150px"}}>
					<MDBRow center className="m-0">
						<span className="tite-text">
							Myensign Jewel Designer
						</span>
					</MDBRow>
				<section id="section_3" className="section main-designer">


					<div className={this.state.isDesigner === false ? "mask-designer " : "mask-designer inactive "} onClick={() => this.onDesignerComponent(this.state.jewellery)}>
						<img src={backgroundImage_2} alt="image"/>
						{!this.state.onDeleteComponent && (
							<div className={this.state.isDesigner === false ? "designer-component" : "designer-component active"}>
								{!window.matchMedia('(max-device-width: 992px)').matches && (
									<DesignerComponent jewellery = {this.state.jewellery}
												onCancelDesignerComponent={() => { this.setState({onDeleteComponent: true});  setTimeout(() => this.onCancelDesignerComponent() ) }}
												showBasketModal = {this.showBasketModal}/>
								)}

							</div>
						)}

						{/*<div className="design-button" >
							<button className="designer-link" onClick={() => this.onDesignerComponent(this.state.jewellery)}>CREATE YOUR DESIGN!</button>
					</div>*/}
					</div>


				</section>
				{/*this.state.isDesigner && (
					<div className="d-flex justify-content-center align-items-center pt-3">
						<button className={this.state.isDesigner ? "cancel-button active" : "cancel-button"} onClick={() => this.onCancelDesignerComponent()}>CLOSE</button>
					</div>
				)*/}

				</MDBContainer>
				<MDBRow center className="m-0 pt-5">
					<span className="tite-text">
					Myensign Unique Jewels
					</span>
				</MDBRow>
				<section id="section_4" className="section main-bracelet">

						<div id="productpage" className="productpage">
						<CarouselComponentJewellery 
											jewellerys={this.state.jewellerys} 
											showBasketModal = {() => {this.setState({onDeleteComponent: true}); this.showBasketModal()}}

											onScrollToDesign = {this.onScrollToDesign}
											onDesignerComponent = {(jewellery) => { this.setState({onDeleteComponent: true});
											  setTimeout(() => this.onDesignerComponent(jewellery), 1000)}}/>


						</div>
				</section>
				<section id="section_5" className="section main-footer">
					<div className="template-footer">
						<MDBContainer className="p-0" style={{maxWidth: "1150px"}}>
							<div className=" bg-2 d-flex justify-content-center footer-div">
								<MDBRow className="m-0" style={{width: "100%"}}>
									<MDBCol center md="auto" className="text-left left-col-footer">
										<MDBRow start className="m-0 pb-3">
											<img src={myensign_icon} className="myensign-icon-footer"/>
										</MDBRow>
										<MDBRow start className="m-0">
										</MDBRow>
										<span className="myensign-footer-text-left">{t('Individuality_cast_in_jewellery')}</span>
									</MDBCol>
									<MDBCol center md="4" className="text-left middle-col-footer">
										{/*<div className="modal-show-btn"><span>H - 1188 Budapest Rákóczi utca 79/B.</span></div>*/}
										<div className="modal-show-btn"><span>E-mail: info@myensign.eu</span></div>
										<div className="modal-show-btn"><span>Tel.: +36 30 9798401</span></div>
										<div className="modal-show-btn"><span></span></div>
									</MDBCol>
									<MDBCol center md="4" className="right-col-footer">
										<button onClick={() => this.showModal(t('Terms_and_conditions'))} className="modal-show-btn"><span>{t('Terms_and_conditions')}</span></button>
										<button onClick={() => this.showModal(t('Legal_statement'))} className="modal-show-btn"><span>{t('Legal_statement')}</span></button>
										<button onClick={() => this.showModal(t('Data_processing_statement'))} className="modal-show-btn"><span>{t('Data_processing_statement')}</span></button>
										<button onClick={() => this.showModal(t('Shipping_and_billing_data'))} className="modal-show-btn"><span>{t('Shipping_and_billing_data')}</span></button>
									</MDBCol>
								</MDBRow>
							</div>
						</MDBContainer>
					</div>
				</section>
			</div>

			<div className="d-block d-lg-none">
			<section id="section_1" className="section main-home d-flex justify-content-center align-items-center" style={{marginTop: "75px"}}>

				<img src={backgroundImage} id="backgroundImage" className="backgroundImage-style"/>

			</section>
			<MDBRow center className="pt-5 pb-4 m-0 ">
					<span className="tite-text">
					Myensign Yachting Jewels
					</span>
				</MDBRow>
			<section id="section_2" className="section main-fotoMontage">
				<CarouselComponentRespo/>
			</section>

			<MDBContainer className="p-0" style={{maxWidth: "1150px"}}>
				<MDBRow center className="m-0">
					<span className="tite-text">
						Myensign Jewel Designer
					</span>
				</MDBRow>
				<section id="section_3" className="section main-designer">
					<div className="touchto-div" onClick={() => {
        											window.location.href = 'https://apps.apple.com/us/app/myensign-jewel-designer/id6463396322';}}>
						<img src={touchto} alt="touchto"/>
					</div>
				</section>
			</MDBContainer>
			<MDBRow center className="m-0 pt-5">
				<span className="tite-text">
				Myensign Unique Jewels
				</span>
			</MDBRow>
			<section id="section_4" className="section main-bracelet">

					<div id="productpage" className="productpage">

					<CarouselComponentJewelleryRespo key={this.state.pageIndex} 
										jewellerys={this.state.jewellerys} 
										showBasketModal = {() => {this.setState({onDeleteComponent: true}); this.showBasketModal()}}
										onScrollToDesign = {this.onScrollToDesign}
										onDesignerComponent = {() => { this.setState({onDeleteComponent: true});  setTimeout(() => {this.onDesignerComponent(this.state.jewellerys) }

										  , 500)}}/>


					</div>
			</section>
			<section id="section_5" className="section main-footer">
				<div className="template-footer">
					<MDBContainer className="px-3" style={{maxWidth: "1150px"}}>
						<div className=" bg-2 d-flex justify-content-center footer-div">
							<MDBRow className="m-0" style={{width: "100%"}}>
								<MDBCol center md="12" className="text-center left-col-footer">
									<MDBRow center className="m-0 pb-1 pt-4">
										<img src={myensign_icon} className="myensign-icon-footer"/>
									</MDBRow>
									<span className="myensign-footer-text-left text-center">{t('Individuality_cast_in_jewellery')}</span>
								</MDBCol>
								<MDBCol center md="12" className="text-left middle-col-footer">
									<div className="modal-show-btn"><span>H - 1188 Budapest Rákóczi utca 79/B.</span></div>
									<div className="modal-show-btn"><span>E-mail: info@myensign.eu</span></div>
									<div className="modal-show-btn"><span>Tel.: +36 30 9798401</span></div>
									<div className="modal-show-btn"><span></span></div>
								</MDBCol>
								<MDBCol center md="12" className="right-col-footer">
									<button onClick={() => this.showModal(t('Terms_and_conditions'))} className="modal-show-btn"><span>{t('Terms_and_conditions')}</span></button>
									<button onClick={() => this.showModal(t('Legal_statement'))} className="modal-show-btn"><span>{t('Legal_statement')}</span></button>
									<button onClick={() => this.showModal(t('Data_processing_statement'))} className="modal-show-btn"><span>{t('Data_processing_statement')}</span></button>
									<button onClick={() => this.showModal(t('Shipping_and_billing_data'))} className="modal-show-btn"><span>{t('Shipping_and_billing_data')}</span></button>
								</MDBCol>
							</MDBRow>
						</div>
					</MDBContainer>
				</div>
			</section>
			</div>



			{this.state.showModal ?
			<FooterModal
				show= {this.state.showModal}
				handleClose = {this.closeModal}
				text= {this.state.text}
				jewellery={this.state.jewellery}
				showModal={this.showModal}
			/>:false}
		</>
	)
  }
}

function MainPageFunc(props) {
    let [searchParams] = useSearchParams();
	const navigate = useNavigate();
    return <MainPage searchParams={searchParams} navigate={navigate}/>;
}

export default withTranslation()(MainPageFunc);

