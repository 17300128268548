import React, { Component } from "react";
import basket from '../assets/media/images/Group 13.png'
import { withTranslation } from 'react-i18next';
import { addItemToBasket } from "./db/basket";

import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody
} from "mdb-react-ui-kit";

class ProductKarte extends Component {
  state = {};

  addToBasket = () => {
    let jewel = {
      "type": this.props.msg.type,
      "picture": this.props.msg.picture,
      "variant": this.props.msg.variant,
      "size": this.props.msg.size,
      "color": this.props.msg.color,
      "preciousMetal": this.props.msg.preciousMetal,
      "collection": this.props.msg.collection,
      "characters": this.props.msg.characters,
      "weight": this.props.msg.weight,
      "price": this.props.msg.price,
      "hufPrice": this.props.msg.hufPrice,
      "usdPrice": this.props.msg.usdPrice
  };
    addItemToBasket(jewel);
    this.props.showBasketModal();
  }

  onMouseEnter = () => {
    this.setState({dropdownOpen: true});
  }

  onMouseLeave = () => {
    this.setState({dropdownOpen: false});
  }

  onDesignerComponent = () => {
    this.props.onDesignerComponent(this.props.msg)
  }


  render() {
    const { t } = this.props;
    return (
      <MDBCard className={this.props.number % 2 == 0 ? "product-card white" : "product-card orange"}>
        <MDBCardBody>
          <MDBRow center className="m-0" style={{height: "230px"}}>
            <img className="picture" src={`data:image/jpeg;base64,${this.props.msg.picture}`}/>
          </MDBRow>
          {/*<MDBRow end className="m-0" style={{height: "60px"}}>
            <div className="tooltip-div" style={{width: "auto"}} onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            <img className="unique" src={this.props.number % 2 == 0 ? unique : unique_w}/>
            {this.state.dropdownOpen && (
              <div className="uniq-back">
              <img className="unique-bg" src={this.props.number % 2 == 0 ? uniquebg : uniquebg_w}/>
              <Player
                autoplay
                loop
                speed={0.8}
                src={animation}
                style={{ height: 'auto', width: '7%', position: 'absolute', top: '263px', right: '41px', margin: 0 }}
              >
              </Player>
              <div className="unique-text">
              <span className="tooltip-text">Unique</span>
              </div>
              <MDBIcon icon="mouse-pointer" className="arrow-icon-rotate" />
            </div>
            )}
           
            </div>
            </MDBRow>*/}
          <MDBRow start className="m-0">
            <span className="ps-1 name">{this.props.msg.type + " - " + this.props.msg.preciousMetal}</span>
          </MDBRow>
          <MDBRow start className="m-0">
            <span className="ps-1 weight">{parseFloat(this.props.msg.weight).toFixed(2) + " gr"}</span>
          </MDBRow>
          <MDBRow start className="m-0 py-2">
            <span className="ps-1 description">{t('Available_in_Silver_Gold_Rose_Gold_White_Gold')}</span>
          </MDBRow>
          <MDBRow className="m-0 pt-4">
            <MDBCol className="p-0  d-flex align-items-center justify-content-start" center lg="5" md="5" size="6">
              <span className="ps-1 price">{"€ " + this.props.msg.price}</span>
            </MDBCol>
            <MDBCol className="pe-3 d-flex align-items-center justify-content-end d-none d-lg-block" center lg="5" md="5" size="6">
                <button type="button" className="p-0 m-0 designer-btn" onClick={() => {this.onDesignerComponent(); this.props.onScrollToDesign()}}>DESIGN</button>
          
            </MDBCol>
            <MDBCol className="p-0 d-flex align-items-center justify-content-end" center md="2"  size="6">
              
              <button type="button" className="busket p-0 m-0" onClick={this.addToBasket}><img src={basket}/></button>
             
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default withTranslation()(ProductKarte);
