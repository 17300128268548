// react imports
import React, { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProductKarte from './ProductKarte';
import { useSwipeable } from 'react-swipeable'

const CarouselComponentJewelleryRespo = (props) => {

  //MDBAlert
  const [starttime, setStarttime] = useState(false);
  const [onTouch, setOnTouch] = useState(false)

  // STATES
  
  const cardsRef  = useRef(null);

    
    

  const responsive = {
    small: {
      breakpoint: { max: 990, min: 0 },
      items: 1,
      partialVisibilityGutter: 1
  }
  };




  useEffect(() => {
     
  }, []);

  const onTouchStart = () => {
    setStarttime(true)
  }
 
  const onTouchMove = (e) => {
    setStarttime(false)
  }

  const onTouchEnd = () => { 
    if(!starttime){
        setOnTouch(false)
    }else {
        setOnTouch(true)
    }
    
    
   }

   const handlers = useSwipeable({
    
    onSwipedLeft: () => {document.body.style.overflow = 'hidden'},
    onSwipedRight: () => {document.body.style.overflow = 'hidden'},
    onSwipedUp: () => {document.body.style.overflow = 'scroll'},
    onSwipedDown: () => {document.body.style.overflow = 'scroll'},
    onSwiping: () => {setStarttime(false)},
    onSwiped: () => {document.body.style.overflow = 'scroll'},
    onTap: () => {document.body.style.overflow = 'scroll'; setStarttime(true)}
   // onTouchStartOrOnMouseDown: () => console.log("swipe")
  });

  const refPassthrough = (el) => {
    handlers.ref(el);
    cardsRef.current = el;
  }
  return (
    <div onTouchMove={onTouchMove} 
    onTouchStart={onTouchStart} 
    onTouchEnd={onTouchEnd} {...handlers} ref={refPassthrough}>
            <Carousel
                additionalTransfrom={0} 
                draggable={false}
                swipeable={true}
                showDots={false}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={false}
                
                centerMode={false}
                keyBoardControl={false}
                customTransition="transform 800ms ease-in-out"
                transitionDuration={800}
                slidesToSlide={1}
                containerClass="carousel-container"
                autoPlay={false}
                autoPlaySpeed={11999}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                removeArrowOnDeviceType={["small"]}
            >
            
            {props.jewellerys.map(
			(o, index) => {
					return (
						<div key={index} center className="px-3 py-0">
							<ProductKarte
								    onDesignerComponent={props.onDesignerComponent}
                    showBasketModal = {props.showBasketModal}
                    onScrollToDesign = {props.onScrollToDesign}
								msg={o}
								number={index+1}
               
							/>
						</div>
									);
		        })}
            </Carousel>
           
    </div>


  );
};

export default CarouselComponentJewelleryRespo;
