import React from 'react';
import Cookies from 'universal-cookie';


import{
	MDBCol,
} from 'mdb-react-ui-kit';

import i18next from 'i18next';
import moment from 'moment';
const cookies = new Cookies();

class LanguageComponent extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if(cookies.get('mylanguage')?.language === undefined){
      this.toEnglish();
    }
  }

  toHungarian = () => {
    i18next.changeLanguage('hu', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      cookies.set('mylanguage', {
        language: 'hu'
      }, { path: '/'}
    );
    });
    moment.locale('hu');
  }

  toEnglish = () => {
    i18next.changeLanguage('en', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      cookies.set('mylanguage', {
        language: 'en'
      }, { path: '/'}
    );
    });
    moment.locale('en');
  }

  render() {
    return (<>
      <MDBCol center md="6" className="pe-2 d-flex justify-content-center  language-col-left">
        <button onClick={this.toEnglish} className="language-btn"><span className={cookies.get('mylanguage')?.language === 'en' ? "active-text" : "inactive-text"}>EN</span></button>
      </MDBCol>
      <MDBCol center md="6" className="ps-2 d-flex justify-content-center language-col-right">
        <button onClick={this.toHungarian} className="language-btn"><span className={cookies.get('mylanguage')?.language === 'hu' ? "active-text" : "inactive-text"}>HU</span></button>
      </MDBCol>
    </>);
  }
}

export default LanguageComponent;
