import React, { Component } from 'react';
import i18n from '../i18n';
import {
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

class Contact extends Component {

  render() {
    return (<>
    <MDBRow className="pb-4">
    <strong className="font-weight-bold">{i18n.t('Myensign_Ltd')}</strong>
    </MDBRow>
    <MDBRow>
      <MDBCol xl="5" md="5">
      <strong className="font-weight-bold">{i18n.t('Tax_number')}</strong>
      </MDBCol>
      <MDBCol xl="7" md="7">
      22940825-2-43  
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xl="5" md="5">
      <strong className="font-weight-bold">{i18n.t('EU_Tax_number')}</strong>
      </MDBCol>
      <MDBCol xl="7" md="7">
      HU22940825243  
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xl="5" md="5">
      <strong className="font-weight-bold">{i18n.t('Company_register_code')}</strong>
      </MDBCol>
      <MDBCol xl="7" md="7">
      01-09-945673  
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xl="5" md="5">
      <strong className="font-weight-bold">{i18n.t('Bank_account_number')}</strong>
      </MDBCol>
      <MDBCol xl="7" md="7">
      HUF 11709002-20621120  
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xl="5" md="5">
      </MDBCol>
      <MDBCol xl="7" md="7">
      EUR 11763093-12515886  
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xl="5" md="5">
      <strong className="font-weight-bold">Bank:</strong>
      </MDBCol>
      <MDBCol xl="7" md="7">
      OTP BANK  
      </MDBCol>
    </MDBRow>

    <MDBRow className="pt-5">
      <MDBCol xl="5" md="5">
      <strong className="font-weight-bold">{i18n.t('Seat')}</strong>
      </MDBCol>
      <MDBCol xl="7" md="7">
      H - 1188 Budapest Rákóczi utca 79/B.  
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xl="5" md="5">
      <strong className="font-weight-bold">E-mail:</strong>
      </MDBCol>
      <MDBCol xl="7" md="7">
      <a href={"mailto:info@myensign.eu"}>info@myensign.eu</a> 
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol xl="5" md="5">
      <strong className="font-weight-bold">Tel.:</strong> 
      </MDBCol>
      <MDBCol xl="7" md="7">
      +36 30 9798401  
      </MDBCol>
    </MDBRow>

    <MDBRow className="pt-5 pb-3">
      <div>
      <strong className="font-weight-bold">{i18n.t('Ipacs_Maria')}</strong>
      </div>
      <div>
      project manager
      </div>
      <div>
      <a href={"mailto:maria.ipacs@myensign.eu"}>maria.ipacs@myensign.eu</a>
      </div>
    </MDBRow>
    <MDBRow className="pb-3">
      <div>
      <strong className="font-weight-bold">{i18n.t('Peter_Kocsis')}</strong>
      </div>
      <div>
      {i18n.t('manufacturing_director')}
      </div>
      <div>
      <a href={"mailto:peter.kocsis@myensign.eu"}>peter.kocsis@myensign.eu</a>
      </div>
    </MDBRow>
    <MDBRow className="pb-3">
      <div>
      <strong className="font-weight-bold">{i18n.t('Dr_Mariann_Zettisch')}</strong>
      </div>
      <div>
      {i18n.t('brand_owner')}
      </div>
      <div>
      <a href={"mailto:mariann.zettisch@myensign.eu"}>mariann.zettisch@myensign.eu</a>
      </div>
    </MDBRow>
    </>
    );
  }
}

export default Contact;
