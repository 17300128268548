// react imports
import React, { useState, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Fotovalogatas } from '../data/fotovalogatas'
import { MDBContainer
          } from 'mdb-react-ui-kit';

const CarouselComponent = (props) => {



  // STATES
  const [screenWidth, setScreenWidth] = useState(window.innerWidth );


    
    

  const responsive = {
    superWidthDesktop: {
        breakpoint: { max: 4000, min: 2000 },
        items: 1,
        partialVisibilityGutter: 10
      },
    tablet_width: {
        breakpoint: { max: 2000, min: 990 },
        items: 1,
        partialVisibilityGutter: 6
    },
    small: {
        breakpoint: { max: 990, min: 0 },
        items: 1,
        partialVisibilityGutter: 1
    }
  };




  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

  useEffect(() => {
    setScreenWidth(window.innerWidth );
  }, []);

  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth );
  };
 

  return (
    <MDBContainer className="p-0"  style={{maxWidth: screenWidth >= 3000 ? "3000px" : 3000 > screenWidth && screenWidth >= 2500 ? screenWidth : 2500 > screenWidth && screenWidth >= 2000 ? "2000px" : 2000 > screenWidth && screenWidth >= 1500 ? screenWidth : 
                                        1500 > screenWidth && screenWidth >= 1000 ? "1000px" : screenWidth}}>
    <div className="galerie-component">
        {screenWidth >= 2500 && (
            <Carousel
                additionalTransfrom={0} 
                draggable={true}
                showDots={false}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={false}
                slidesToSlide={1}
                centerMode={false}
                keyBoardControl={false}
                customTransition="transform 2900ms ease-in-out"
                transitionDuration={2900}
                containerClass="carousel-container"
                autoPlay={false}
                autoPlaySpeed={1000}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
               
            >
            
       
                {Fotovalogatas.withThree.map((k,n) => {
                    return( 
                    <div key={n+300} className="d-flex align-items-center" style={{ width: "100%", height: "950px"}}>
                        <div className="foto-block" style={{ width: "100%"}}>
                            <div className="left-side">
                                <div className="foto1">
                                <div className="view hover-overlay" >
                                    <img src={k.fekvo} className="gallery-12" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="foto2">
                                    <div className="view hover-overlay" >
                                        <img src={k.bal} className="gallery-4" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                    <div className="foto3">
                                    <div className="view hover-overlay" >
                                        <img src={k.jobb} className="gallery-8" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="right-side">
                                <div className="foto4">
                                <div className="view hover-overlay" >
                                    <img src={k.allo} className="gallery-9" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="foto-block" style={{ width: "100%"}}>
                            <div className="left-side">
                                <div className="foto1">
                                <div className="view hover-overlay" >
                                    <img src={k.fekvo_2} className="gallery-12" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="foto2">
                                    <div className="view hover-overlay" >
                                        <img src={k.bal_2} className="gallery-4" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                    <div className="foto3">
                                    <div className="view hover-overlay" >
                                        <img src={k.jobb_2} className="gallery-8" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="right-side">
                                <div className="foto4">
                                <div className="view hover-overlay" >
                                    <img src={k.allo_2} className="gallery-9" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="foto-block" style={{ width: "100%"}}>
                            <div className="left-side">
                                <div className="foto1">
                                <div className="view hover-overlay" >
                                    <img src={k.fekvo_3} className="gallery-12" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="foto2">
                                    <div className="view hover-overlay" >
                                        <img src={k.bal_3} className="gallery-4" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                    <div className="foto3">
                                    <div className="view hover-overlay" >
                                        <img src={k.jobb_3} className="gallery-8" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="right-side">
                                <div className="foto4">
                                <div className="view hover-overlay" >
                                    <img src={k.allo_3} className="gallery-9" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    )
                    })}
            </Carousel>
        )}
        {2500 > screenWidth && screenWidth >= 1500 && (
            <Carousel
                additionalTransfrom={0} 
                draggable={true}
                showDots={false}
                responsive={responsive}
                infinite={true}
                rewind={false}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={false}
                
                centerMode={false}
                keyBoardControl={false}
                customTransition="transform 19000ms ease-in-out"
                transitionDuration={19000}
                autoPlay={true}
                autoPlaySpeed={20000}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
               
            >
            
                {Fotovalogatas.withTwo.map((k,n) => {
                    return( 
                    <div key={n+100} className="d-flex align-items-center" style={{ width: "100%", height: "950px"}}>
                        <div className="foto-block" style={{ width: "100%"}}>
                            <div className="left-side">
                                <div className="foto1">
                                <div className="view hover-overlay" >
                                    <img src={k.fekvo} className="gallery-12" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="foto2">
                                    <div className="view hover-overlay" >
                                        <img src={k.bal} className="gallery-4" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                    <div className="foto3">
                                    <div className="view hover-overlay" >
                                        <img src={k.jobb} className="gallery-8" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="right-side">
                                <div className="foto4">
                                <div className="view hover-overlay" >
                                    <img src={k.allo} className="gallery-9" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="foto-block" style={{ width: "100%"}}>
                            <div className="left-side">
                                <div className="foto1">
                                <div className="view hover-overlay" >
                                    <img src={k.fekvo_2} className="gallery-12" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="foto2">
                                    <div className="view hover-overlay" >
                                        <img src={k.bal_2} className="gallery-4" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                    <div className="foto3">
                                    <div className="view hover-overlay" >
                                        <img src={k.jobb_2} className="gallery-8" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="right-side">
                                <div className="foto4">
                                <div className="view hover-overlay" >
                                    <img src={k.allo_2} className="gallery-9" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    )
                })}
            </Carousel>
        )}
        {1500 > screenWidth && screenWidth >= 0 && (
            <Carousel
                additionalTransfrom={0} 
                draggable={true}
                showDots={false}
                responsive={responsive}
                infinite={true}
                rewind={false}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={false}
                
                centerMode={false}
                keyBoardControl={false}
                containerClass="carousel-container"
                customTransition="transform 19000ms ease-in-out"
                transitionDuration={19000}
                autoPlay={true}
                autoPlaySpeed={20000}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
               
            >
            
                {Fotovalogatas.withOne.map((k,n) => {
                    return( 
                    <div key={n+200} className="d-flex align-items-center" style={{ width: "100%", height: "950px"}}>
                        <div className="foto-block"  style={{ width: "100%"}}>
                            <div className="left-side">
                                <div className="foto1">
                                <div className="view hover-overlay" >
                                    <img src={k.fekvo} className="gallery-12" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="foto2">
                                    <div className="view hover-overlay" >
                                        <img src={k.bal} className="gallery-4" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                    <div className="foto3">
                                    <div className="view hover-overlay" >
                                        <img src={k.jobb} className="gallery-8" alt="" style={{objectFit: "cover"}} />
                                    </div>
                                    <div className="mask flex-center" overlay="red-strong"/>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="right-side">
                                <div className="foto4">
                                <div className="view hover-overlay" >
                                    <img src={k.allo} className="gallery-9" alt="" style={{objectFit: "cover"}}/>
                                </div>
                                <div className="mask flex-center" overlay="red-strong"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    )
                })}
            </Carousel>
        )}
           
    </div>
    </MDBContainer>

  );
};

export default CarouselComponent;
