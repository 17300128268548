import React, { Component } from 'react';


class DataProtection extends Component {

  render() {
    return (
      <>
            <p align="center"><a href="http://www.myensign.eu/">http://www.myensign.eu/</a></p>
            <p>MYENSIGN Kft. (the &ldquo;Seller&rdquo;) operates a web store on the www.myensign.eu website (the &ldquo;Website&rdquo;) and, within the framework thereof, processes the data of those who visit and register on the Website (the &ldquo;User&rdquo;). In relation to data processing, the Seller hereby informs the Users of the personal data it is processing on the Website and its principles and practices applied when processing personal data as well as the method and possibilities for exercising the rights of data subjects.</p>
            <p>The Seller respects the right to personal data of the visitors to its Website and processes any recorded personal data confidentially in accordance with data protection laws and international recommendations, as laid down in this data processing statement.</p>
            <p>Use of the Website is considered as acceptance by the User of the following terms and conditions and consent to data processing as described below.</p>
            <p><strong className="font-weight-bold">Definitions</strong></p>
            <ol>
                <li><strong className="font-weight-bold">Data subject (User):</strong> means any natural person who is identified or can, whether directly or indirectly, be identified on the basis of any specific personal data;</li>
                <li><strong className="font-weight-bold">Personal data:</strong> means any information relating to the data subject, in particular by reference to his name, an identification number or to one or more factors specific to his physical, physiological, mental, economic, cultural or social identity, and any reference drawn from such information pertaining to the data subject;</li>
                <li><strong className="font-weight-bold">Registration:</strong> means joining the Myensign club by submitting the personal data of the User.</li>
                <li><strong className="font-weight-bold">Club member:</strong> means any User who joined the Myensign club by registering on the Website.</li>
            </ol>
            <p> <strong className="font-weight-bold">Purpose of data processing</strong></p>
            <p>The purpose of data processing is to enable the Seller to offer the services available on its Website at an appropriate level and in accordance with statutory requirements:</p>
            <p>1 &nbsp; to manage the orders for, and delivery of, the products available on the Website and the invoicing thereof,<br/>2 &nbsp; to facilitate communication with the Users,<br/>3 &nbsp; where appropriate, to provide specific services in a customised manner (discounts associated with club membership)</p>
            <p> <strong className="font-weight-bold">Legal basis of data processing</strong></p>
            <p>The disclosure of data on the Website and data processing by the Seller are at all times  <strong className="font-weight-bold">based on</strong>  <strong className="font-weight-bold">voluntary disclosure&nbsp;</strong>by the Users pursuant to point a) of Section 5(1) of Act CXII of 2011 on the right of informational self-determination and on freedom of information (the &ldquo;Info Act&rdquo;) and Act CVIII of 2001 on certain aspects concerning electronic commerce and information society services.</p>
            <p>Accordingly, the Seller processes and records the data of the Users only to the extent expressly approved by the Users. The Seller does not, under any circumstances, disclose any personal data which came to its knowledge to any third party without the User&rsquo;s express consent, except if required by law or where personal data was submitted specifically for public disclosure on the Website (e.g. comment or advertisement).</p>
            <p> <strong className="font-weight-bold">Name of the data processor</strong></p>
            <p>Name: MYENSIGN KFT.<br/>Registered office: 1094 Budapest, Liliom u. 48.<br/>Head office: 1051 Budapest, Arany J. u. 34.<br/>Name of representative: Mariann Zettisch<br/>Company registration number: 01-09-945673<br/>Court of registration: Metropolitan Court as the Court of Registration<br/>Tax number: 229840825-2-43<br/>Telephone: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<br/>E-mail: <a href="mailto:info@myensign.eu">info@myensign.eu</a></p>
            <p> <strong className="font-weight-bold">Duration of data processing</strong></p>
            <p>The processing of personal data provided by the Users begins with registration and ends when the User unsubscribes from the service with the given user name or cancels his/her registration.</p>
            <p>During the operation of the system, logged data that is automatically and technically recorded is stored in the system from is generation for a period justified to ensure the functioning of the system for a period of 6 months.</p>
            <p> <strong className="font-weight-bold">Scope of processed data</strong></p>
            <p>1 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Technical data:</p>
            <p>Certain data and the IP addresses of the computers of Users who visit the MyEnsign.eu sites are logged for the purpose of recording the Users&rsquo; visits. The Seller uses such data only for making statistics, for example, to determine which parts of the MyEnsign.eu sites are visited by the Users and how often, and how much time they spend there.</p>
            <p>The Seller does not link the IP addresses to any other data on the basis of which the User could be personally identified, nevertheless these addresses and log files may be considered as personal data in specific cases.</p>
            <p>2 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Definition of the scope of data that must be provided during registration. The Seller keeps confidential the data provided on the Website during registration. Registration requires the provision of name, e-mail address and country. Personal data recorded during ordering (such as name, address, telephone number and e-mail address) are used by the Seller to fulfil orders.</p>
            <p>3 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Cookies:</p>
            <p>When downloading certain parts of the Website, the Seller installs small data files containing, in certain cases, personal data (cookies) on the User&rsquo;s computer for the purposes of data recording, identification of Users and facilitating subsequent User visits. Users can set in the browser used to access the Internet the provision of warnings if the Seller wants to place a cookie on their computer and may disable the sending of cookies at any time.</p>
            <p>However, certain parts of the Website may not work properly if cookies are not accepted and it can also happen that the User is not granted authorisation to access certain information.</p>
            <p> <strong className="font-weight-bold">Persons authorised to access the data</strong></p>
            <p>The Seller and the Seller&rsquo;s internal staff are primarily entitled to know the data however they must not publish the data or transfer the data to any third parties and are entitled and obliged to use them only for the purpose specified in this Data Processing Statement.</p>
            <p>The Seller, acting in its capacity as data processor, is entitled and obliged to transmit any personal data available to it and stored regularly to the competent authorities where the transmission of data is required by law or final obligation imposed by authorities. The data processor may not be held responsible for such data transmission or the consequences resulting therefrom.</p>
            <p> <strong className="font-weight-bold">Data processing</strong></p>
            <p>The Seller may use third party data processors (e.g. system operator, accountant, courier) to operate the underlying information technology systems, fulfil orders and settle accounts. The Seller is not responsible for the data processing practice of such third parties.</p>
            <p>The Seller uses the following data processors:<br/>Name: FEDEX<br/>Address: Airport Business Park C5, 2220 Vecs&eacute;s, Lőrinczi u. 59.<br/>Correspondence address: Airport Business Park C5, 2220 Vecs&eacute;s, Lőrinczi u. 59.<br/>Telephone number: 06 (40) 980 980<br/>E-mail address: info.bud.hu@mail.fedex.com<br/>Web URL: <a href="http://www.fedex.com/hu">www.fedex.com/hu</a><br/>Activity: International shipping - courier service</p>
            <p> <strong className="font-weight-bold">Data security</strong></p>
            <p>The Seller ensures the security of personal data provided by the Users both during network communication and the storage and keeping of data.</p>
            <p> <strong className="font-weight-bold">Rights of Users</strong></p>
            <p>1 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Right to receive information</p>
            <p>Users are entitled at any time to request information on the personal data concerning them and kept by the Seller and, with the exception of the e-mail address provided during registration, modify such data at any time according to the terms and conditions specified in the General Contractual Terms and Conditions.</p>
            <p>The Seller informs the User, upon request, of the data relating to the User which it manages, the purpose, legal grounds and duration of data processing as well as the persons who receive or have received the User&rsquo;s data and the purpose thereof. The Seller provides the requested information in writing within 30 days of the receipt of the request.</p>
            <p>Users may contact the Seller&rsquo;s staff with any questions or remarks concerning data processing via any of the contact details below.</p>
            <p>2 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Users may request the deletion, rectification or blocking of their data</p>
            <p>Users may at any time request the rectification of their data which has been recorded incorrectly or the deletion of their data via any of the contact details below. The Seller will delete the data within 3 working days of the receipt of the request. In this case, the data is not recoverable. Cancellation does not apply to data processing required by law (e.g. accounting regulations) and the Seller retains such data for the required period of time.</p>
            <p>Users may also request the blocking of their data. The Seller will block personal data if requested by the data subject or if it can be assumed on the basis of available information that deletion would harm the legitimate interests of the User. Personal data blocked in this manner may only be processed for as long as the purpose of data processing that excluded the deletion of personal data prevails.</p>
            <p>The Seller will notify the User and all other persons to whom it has transmitted the data for data processing of the rectification, blocking or deletion. Notice may be ignored if it does not violate the legitimate interests of the User in view of the purpose of data processing.</p>
            <p>If the data processor does not fulfil the request for rectification, blocking or deletion, it shall state the factual and legal grounds for rejecting the request for rectification, blocking or deletion in writing within 30 days of the receipt of the request.</p>
            <p>3 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Users may object to the processing of their personal data</p>
            <p>Users may object to the processing of their personal data. The Seller investigates the objection within the shortest possible time in a 15-day time limit from the receipt of the request and adopts a decision as to the merits thereof, and notifies the applicant of its decision in writing.</p>
            <p>Data subjects can exercise their rights via the following contact details:<br/>Name: Myensign D&iacute;szmű forgalmaz&oacute; Kft.<br/>Correspondence address: 1094 Budapest, Liliom u. 48. III/6.<br/>E-mail: <a href="mailto:info@myensign.eu">info@myensign.eu</a><br/>Pursuant to the Info Act and the Civil Code (Act IV of 1959), Users may</p>
            <p>1 &nbsp; &nbsp; &nbsp; Enforce their rights in court, or<br/>2 Address the National Authority for Data Protection and Freedom of Information (1125 Budapest, Szil&aacute;gyi Erzs&eacute;bet fasor 22/c.;<a href="http://www.naih.hu/">&nbsp;www.naih.hu&nbsp;</a>).</p>
            <p>The Seller may enforce damages against the User if the User provided the data of any third person to use the service or caused damage in any manner while using the Website. In such case, the Seller will use its best efforts to assist the competent authorities in seeking to establish the identity of the offender.</p>
            <p> <strong className="font-weight-bold">Use of e-mail addresses</strong></p>
            <p>The Seller does not send electronic mail to the e-mail addresses provided during registration, except for informative e-mails related to the services used.</p>
            <p>E-mail addresses are primarily managed in order to identify the User, fulfil orders and communicate, so e-mails are sent primarily to that effect.</p>
            <p>The Seller periodically sends its Users registered on the Website, namely to Club Members, special offers, promotions or catalogues for the products sold by the Seller, to which Club Members consent in advance by accepting the privacy statement during the registration process.</p>
            <p> <strong className="font-weight-bold">Miscellaneous provisions</strong></p>
            <p>The Seller reserves the right to unilaterally amend this Data Processing Statement with prior notice to the Users. Following the entry into force of the amendment, Users implicitly accept what is laid down in the amended Data Processing Statement by using the service.</p>
            <p>The Seller may enforce damages against the User if the User provided the data of any third person during registration or caused damage in any manner while using the Website. In such case, the Seller will use its best efforts to assist the competent authorities in seeking to establish the identity of the offender.</p>
            <p>Effective date of the Data Processing Statement: 2013</p>
            <p>The Seller disclaims any liability for any information published by Users on the Internet by using the MyEnsign.eu sites. If the Seller becomes aware that a User provided the personal data of a third person during registration or otherwise in a manner in violation of the rights of any third person or law, or is using personal data or other data not qualifying as personal data, which is publicly available on the MyEnsign.eu sites pages or has been obtained unlawfully, in a manner violating the personal rights of any third person or in violation of law (e.g. spam), or is otherwise in breach of these privacy regulations or has caused damage while using the MyEnsign.eu sites, the Seller will take the necessary legal action to remedy the damage caused and to have the person acting in the above manner made responsible before the law. In such case, the Seller will use its best efforts to assist the competent authorities in seeking to establish the identity of the offender and make him/her responsible.</p>
            <div>This website uses Google Analytics, a web analytics service provided by Google, Inc. (&quot;Google&quot;). Google Analytics uses &quot;cookies&quot;, which are text files placed on your computer, to help the website analyse how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will not associate the information generated by the cookies with any other data. You may refuse the use of cookies by selecting the appropriate settings on your browser, however, please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you in the manner and for the purposes set out above. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage.</div>
    </>
    );
  }
}

export default DataProtection;
