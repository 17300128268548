// react imports
import React from "react";

// IMAGES IMPORTS
import orderCheckImg from "../assets/media/images/svg/order-check.svg";

/**
 * Props:
 * @param h1Title: string
 * @param message: string
 * @returns 
 */
const ThankYouPanel = ({
  h1Title,
  message
}) => {
  return (
    <div className="thank-you-panel">
      <div className="container-fluid">
        <img src={orderCheckImg} alt="Check" />

        <div>
          <h1>{h1Title}</h1>
          {message}
        </div>
      </div>
    </div>
  );
};

export default ThankYouPanel;