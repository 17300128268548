import React, { Component } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class ProductInformation extends Component {
  constructor(props) {
    super(props);
    	this.state = {text: ""
									};
 	};


  render() {
    return (<div style={{maxWidth: "100%"}} className="px-2">
              
              {cookies.get('mylanguage')?.language === "en" ? <>

                <p className="ql-align-center"><strong style={{color: "black"}}>Product guide</strong></p><p><span style={{color: "rgb(135, 135, 104)"}}></span></p>
                <p className="ql-align-center"><strong>MYENSIGN Yachting Jewels moulds the international nautical alphabet codes – ensigns – in jewellery, exclusively using precious 
                metals, precious stones and the highest quality enamel technology.</strong></p><p><strong></strong></p><p><strong>Quality features</strong></p>
                <p>Our jewellery is made out of special silicon rubber and is available to order in black – white – grey – brown – bright red – bright blue – bright yellow colours. </p>
                <p>The special silicon rubber is 9 mm wide and 3 mm high. The length and size of the special silicon jewellery straps can be selected and customised in the Jewel Designer section. 
                In the case of the purchase of ready items, the system automatically asks you to choose the exact size before finalising the order.</p><p><strong>When specifying the exact size of bracelets, 
                please take into account that the standard comfort size is the tightly measured wrist size + 1.5 cm.</strong></p><p><strong>Silver products:</strong></p>
                <p>Our silver jewellery is made of silver 925 and is rhodium-plated. </p><p>The&nbsp;<strong>stone straps</strong>&nbsp;made 
                of silver are decorated with 5 x 1.5 mm diameter zirconium stones. </p><p>The&nbsp;<strong>full stone</strong>&nbsp;decorative elements made of 
                silver contain 23 x 1.5 mm diameter zirconium stones.</p><p><strong>Gold products:</strong></p><p>Our gold jewellery 
                is made from gold 585 14 carat and can be ordered in three different colours - White gold, Yellow gold and Rose gold.</p><p>The white gold jewellery 
                is rhodium-plated.</p>
                <p>The&nbsp;<strong>stone-decorated straps</strong>&nbsp;contain 5 x 1.5 mm diameter white / black brilliant diamonds as the buyer requires, the quality category of which is TW / VVS1 / M1.</p>
                <p>The&nbsp;<strong>full stone</strong>&nbsp;decorative elements contain 23 x 1.5 mm diameter white / black brilliant diamonds as the buyer requires, the quality category of which is TW / VVS1 / M1.</p>
                <p>All MYENSIGN products come with an <strong>EU</strong> standard metal mark, while the authenticity of the products is certified by the invoice and certificate in all cases.</p>
                <p>The nautical alphabet symbols are made using modern enamelling techniques. MYENSIGN has come up with three colour collections, namely the MYENSIGN ORIGINAL, 
                MYENSIGN MONOCHROME and MYENSIGN CHOCOLATE collections.</p>
                <p><strong>ORIGINAL</strong> collection: &nbsp;the original colours of the official, international flag codes</p>
                <p><strong>MONOCHROME </strong>collection: &nbsp;the monochrome inverse of the original colours of the flag codes</p>
                <p><strong>CHOCOLATE</strong> collection: &nbsp;the sepia version of the original colours of the flag codes</p>
                <p><strong>Information on size</strong></p><p>Nautical codes and numbers 12 mm x 10 mm</p><p>Anchors, Compass, Ships 12 mm x 10 mm</p>
                <p>Jewelled strap / Decorative strap 4 mm x 10 mm</p>
                <p>Jewelled square / Decorative square 12 mm x 10 mm</p><p>Earrings 11 mm x 10 mm</p><p>Cufflinks standard 12 mm x 10 mm</p>
                <p>Cufflinks oval 21 mm x 18 mm</p>
                <p><strong>Handling instructions</strong></p>
                <p>The jewellery should not come into contact with solvents, chemicals, beauty care products or cosmetics. Saunas, solariums, chlorified water, spa water and salty water have a harmful effect on the products.</p>

                <p>The jewellery should be protected from extreme temperature changes.</p>
                <p>The jewellery should be protected from mechanical impact (striking, friction, scratching, dropping, grabbing).</p>

                <p>It is not recommended to wear the jewellery whilst sleeping or doing sport. In the case of extended use, the special silicon rubber can harden.</p>
                <p>As a precious metal, silver can oxidise in saline water or due to acidic sweat, the rhodium layer can also be corroded away.</p>
                <p>Acidic sweat - acidic skin pH can have a harmful, corrosive effect on both the silver and special silicon rubber.</p>

                <p><strong><span >Purchase procedure</span></strong></p>
                <p><span >Our customers can create their own jewelry using our Jewel Designer section. Individuality cast in jewellery, with a message conveyed in special codes using precious materials.</span></p>
                <p><span >Myensign Jewel Designer as an individual App is ready to download in the App Store too for Iphone and Ipad devices.</span></p>

                <p><span >Our Jewel Designer shows the weight and price of the jewelry during the configuration in EUR and HUF currencies, so it's easy to decide what type of jewelry in what price-range the customer would like to purchase. The system generates the HUF prices on the daily rates of the Hungarian National Bank. As payment options, it is possible to pay with credit/debit card or pre-bank transfer in our online store.</span></p>
                <p><span >There is also an availability to purchase Ready to buy jewelries in our webshop.</span></p>
                <p><span >In virtue of the precious metal trading regulations of the European Union and the Security Council of the United Nations, 
                  we kindly inform our customers that purchasing over 300.000 HUF /approx. 800 EUR, depending 
                  on the daily exchanging rates/ it is necessary to finalize the purchase with the customers personal ID datas.</span></p>
                <p><span >We are shipping to EU countries, Switzerland and to Great Britain.</span></p>
                <p><span >Our system sends a confirmation by e-mail after every purchase, the delivery time of the jewelries are 10-14 working days, while in the case of countries subject to duty, this may even be as much as 20 days due to the different customs handling practises. 
                  The shipment and delivery of the purchased goods will be completed by transportation or courier companies based on the level of the final value of the invoice /Hungarian Post Logistics, Fedex/</span></p>
                <p><span >In case of personal interests or to execute an order please don’t hesitate to visit our Official Reseller Partners.</span></p>

                </>:<>

                <p className="ql-align-center"><strong style={{color: "black"}}>Ékszer termékismertető</strong></p>
                <p className="ql-align-center"><strong>A nemzetközi tengerészeti kódlobogók ékszerekbe öntve, kizárólag nemesfémek, drágakövek és a legmagasabb minőségű 
                zománc technológia felhasználásával</strong></p>
                <p><span>Ékszereink alapja speciális szilikon gumiból készül, 
                amely - fekete - fehér - szürke - barna - élénk piros - élénk kék - élénk sárga színekben rendelhető. A speciális szilikon gumik szélessége 9 mm, magassága 3 mm. A speciális szilikonból készült ékszerpántok méretezése az Ékszertervezőben testre szabható. </span></p>
                <p><strong>Karkötők pontos méretének megadásánál kérjük figyelembe venni, 
                hogy a szorosan mért csukló méret + 1,5 cm a standard kényelmes viselet.</strong></p>
                <p><strong>
                Ezüst termékek</strong></p>
                <p><span>Ezüstből készült ékszereink 925% 
                finomságúak és ródium réteggel vannak ellátva. Ezüstből készült&nbsp;</span><strong>köves pántok</strong>
                <span>&nbsp;egyenként 5 db 1.5 mm átmérőjű cirkónia követ tartalmaznak. Ezüstből készült&nbsp;</span>
                <strong> köves kocka</strong><span>&nbsp;díszítő elemek egyenként 23 db 1.5 mm átmérőjű cirkónia követ tartalmaznak.</span></p>
                <p><strong>Arany termékek</strong><span></span></p>
                <p><span>
                Arany ékszereink 585 finomságúak, 14 karátosak és három féle színben rendelhetők: Fehér arany - Sárga arany - Rosé arany </span></p>
                <p><span>A fehér aranyból készült ékszerek ródium réteggel vannak ellátva. </span></p>
                <p><span>Aranyból készült&nbsp;</span><strong > köves pántok</strong>
                <span>&nbsp;egyenként 5 db 1.5 mm átmérőjű fehér / fekete briliáns gyémántot tartalmaznak vevői választás szerint, melyek minőségi besorolása :
                &nbsp;TW / VVS1 / M1</span></p>
                <p><span>Aranyból készült&nbsp;</span><strong > köves kocka </strong><span>
                &nbsp;díszítő elemek egyenként 23 db 1.5 mm átmérőjű&nbsp;fehér / fekete briliáns gyémántot&nbsp;tartalmaznak vevői választás szerint, melyek minőségi besorolása :
                &nbsp;&nbsp;TW / VVS1/ M1</span></p><p><span >Minden MYENSIGN termék </span>
                <strong >EU </strong><span >konvenciós fémjellel van ellátva, a termékek eredetiségét minden esetben számla és 
                certifikát igazolja.&nbsp;A tengerészeti ABC kódlobogóit modern zománcozási technológiával készítjük, színvilágban három kollekciót közül lehet választani: </span></p>
                <p><strong >ORIGINAL </strong><span >&nbsp;collection:&nbsp;&nbsp;a hivatalos nemzetközi kódlobogók eredeti 
                színes színeivel</span></p><p><strong >MONOCHROME</strong><span >&nbsp;collection:&nbsp;a kódlobogók eredeti színeinek monochrome verziójával</span></p>
                <p><strong >CHOCOLATE</strong><span >&nbsp;collection:&nbsp;&nbsp;a kódlobogók eredeti színeinek sephia változatával</span></p>
                <p><strong >Karakterek pontos méretezése</strong></p>
                <p><span >Tengerészeti kódlobogók és számok: 12 mm x 10 mm</span></p><p><span >Horgony, Iránytű, Hajó: 12 mm x10 mm</span></p><p><span >Köves / Sima pánt: 
                4 mm x 10 mm</span></p><p><span >Telikő kocka / Sima kocka: 12 mm x 10 mm</span></p><p><span >Fülbevaló: 11 mm x 10 mm</span></p>
                <p><span >Mandzsetta standard: 12 mm x 10 mm</span></p><p><span >Mandzsetta ovál: 21 mm x 18 mm</span></p>
                <p><strong><span >Kezelési útmutató</span></strong></p>
                <p><span >A termékek ne érintkezzenek oldószerekkel, vegyszerekkel, szépségápolási termékekkel, kozmetikai szerekkel. Szauna, szolárium, klóros víz, termál víz, 
                sós víz károsítja az ékszert! </span></p><p><span >Szélsőséges hőmérséklet változástól az ékszert kímélni kell!</span></p><p><span >Mechanikai sérülésektől az ékszert óvni 
                kell /ütés - súrlódás - karcolás - leejtés - rángatás/</span></p><p><span >Az ékszerekben aludni vagy sportolni nem ajánlott!</span></p>
                <p><span >Tartós használat esetén a 
                speciális szilikon gumi keményedhet.</span></p>
                <p><span >Az ezüstre mint nemesfémre a sós víz és a savas izzadtság oxidáló hatással bír, a ródium 
                réteget is lemarhatja!</span></p>
                <p><span >Savas izzadtság - savas bőr ph káros, maró hatással lehet az ezüstre és a speciális szilikon gumira egyaránt!</span></p>
                <p><strong><span >Vásárlás menete</span></strong></p>
                <p><span >Vásárlóink  megtervezhetik saját ékszereiket Ékszertervezőnk használatával. Ezáltal egyedi, különleges ékszereket vásárolhatnak, melyek  egyedi üzenetet közvetítenek.  Személyes  mondanivaló kódokban,  ékszerbe zárt ex-libris.</span></p>
                <p><span >A Myensign Jewel Designer mint önálló applikáció az App Store-ban is letölthető Iphone és Ipad készülékekre.</span></p>

                <p><span >Ékszertervezőnk az ékszer tervezése közben folyamatosan közli az ékszer súlyát, árát  EUR és HUF pénznemekben, így a vásárló könnyen eldöntheti, 
                  milyen típusú és árkategóriájú ékszert szeretne vásárolni. A rendszer a HUF árazást a Magyar Nemzeti Bank napi árfolyamán végzi. 
                  A webáruházban bankkártyás fizetésre illetve előre utalásra van lehetőség.</span></p>
                <p><span >Az egyedi tervezés mellett késztermékek is elérhetők a webáruházban.</span></p>
                <p><span >Tájékoztatjuk a kedves érdeklődőket, hogy az Európai Unió és az ENSZ Biztonsági Tanács nemesfém kereskedelemről szóló rendeletének megfelelően, 
                  a 300.000 HUF- ot meghaladó vásárlások esetén a vásárlás véglegesítéséhez személyazonosító okmány adatainak megadása szükséges.</span></p>
                <p><span >Szállítást az Európai Unió országaiba, Svájcba és Nagy Britannia területére vállalunk.</span></p>
                <p><span >Minden vásárlásról rendszerünk visszaigazóló e-mailt küld, az ékszerek szállítási ideje 10-14 munkanap, vámkezelést igénylő országok esetében ez akár 
                  20 munkanapot is igénybe vehet az eltérő vámkezelési szokások miatt. A szállítást és kézbesítést a Magyar 
                  Posta Logisztika illetve a Fedex futárszolgálat végzik a végső számla értéktől függően.</span></p>
                <p><span >Személyes vásárlás esetén keressék bizalommal Hivatalos Viszonteladó Partnereinket, ahol késztermékek vásárlására és rendelés leadásra is van lehetőség.</span></p>

                </>}

      </div>
    );
  }
}

export default ProductInformation;
