import React from 'react';
import {
    MDBInput
} from 'mdb-react-ui-kit';
import { setCustomerInfo, getCustomerInfo, initCustomerInfo} from './db/customer';

class CustomerEditor extends React.Component {

    state = { "customer": {}};

    constructor(props) {
        super(props);
        initCustomerInfo();
    }

    componentDidMount() {
        this.loadCustomerInfo();
    }

    loadCustomerInfo = () => {
        let self = this;
        getCustomerInfo().then((customer) => {
            self.setState({"customer": customer});
        });
    }
    
    onEmailChange = (event) => {
        let newValue = event.target.value;
        let cust = this.state.customer;
        if (cust === undefined) {
          return;
        }
        cust.email = newValue;
        setCustomerInfo(cust).then(() => this.loadCustomerInfo());
    }

    render() {
        return <div>
            Email: <MDBInput id='email'
            type='text'
            value={this.state.customer?.email}
            onChange={this.onEmailChange}/><br/>
            Phone: {this.state.customer?.phone}<br/>
            <b>Billing</b><br/>
            Name: {this.state.customer?.billing?.name}<br/>
            <b>Shipping</b><br/>
            Name: {this.state.customer?.shipping?.name}<br/>
        </div>
    }
}

export default CustomerEditor;