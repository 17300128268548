import allo1 from '../assets/media/images/allo/1.jpg';
import allo2 from '../assets/media/images/allo/2.jpg';
import allo3 from '../assets/media/images/allo/3.jpg';
import allo4 from '../assets/media/images/allo/4.jpg';
import allo5 from '../assets/media/images/allo/5.jpg';
import allo6 from '../assets/media/images/allo/6.jpg';
import allo7 from '../assets/media/images/allo/7.jpg';
import allo8 from '../assets/media/images/allo/8.jpg';
import allo9 from '../assets/media/images/allo/9.jpg';
import allo10 from '../assets/media/images/allo/10.jpg';
import allo11 from '../assets/media/images/allo/11.jpg';
import allo12 from '../assets/media/images/allo/12.jpg';
import allo13 from '../assets/media/images/allo/13.jpg';
import allo14 from '../assets/media/images/allo/14.jpg';

import fekvo1 from '../assets/media/images/szeles/1.jpg';
import fekvo2 from '../assets/media/images/szeles/2.jpg';
import fekvo3 from '../assets/media/images/szeles/3.jpg';
import fekvo4 from '../assets/media/images/szeles/4.jpg';
import fekvo5 from '../assets/media/images/szeles/5.jpg';
import fekvo6 from '../assets/media/images/szeles/6.jpg';
import fekvo7 from '../assets/media/images/szeles/7.jpg';
import fekvo8 from '../assets/media/images/szeles/8.jpg';
import fekvo9 from '../assets/media/images/szeles/9.jpg';
import fekvo10 from '../assets/media/images/szeles/10.jpg';
import fekvo11 from '../assets/media/images/szeles/11.jpg';
import fekvo12 from '../assets/media/images/szeles/12.jpg';
import fekvo13 from '../assets/media/images/szeles/13.jpg';
import fekvo14 from '../assets/media/images/szeles/14.jpg';

import kicsi1_a from '../assets/media/images/kicsi/1a.jpg';
import kicsi2_a from '../assets/media/images/kicsi/2a.jpg';
import kicsi3_a from '../assets/media/images/kicsi/3a.jpg';
import kicsi4_a from '../assets/media/images/kicsi/4a.jpg';
import kicsi5_a from '../assets/media/images/kicsi/5a.jpg';
import kicsi6_a from '../assets/media/images/kicsi/6a.jpg';
import kicsi7_a from '../assets/media/images/kicsi/7a.jpg';
import kicsi8_a from '../assets/media/images/kicsi/8a.jpg';
import kicsi9_a from '../assets/media/images/kicsi/9a.jpg';
import kicsi10_a from '../assets/media/images/kicsi/10a.jpg';
import kicsi11_a from '../assets/media/images/kicsi/11a.jpg';
import kicsi12_a from '../assets/media/images/kicsi/12a.jpg';
import kicsi13_a from '../assets/media/images/kicsi/13a.jpg';
import kicsi14_a from '../assets/media/images/kicsi/14a.jpg';

import kicsi1_b from '../assets/media/images/kicsi/1b.jpg';
import kicsi2_b from '../assets/media/images/kicsi/2b.jpg';
import kicsi3_b from '../assets/media/images/kicsi/3b.jpg';
import kicsi4_b from '../assets/media/images/kicsi/4b.jpg';
import kicsi5_b from '../assets/media/images/kicsi/5b.jpg';
import kicsi6_b from '../assets/media/images/kicsi/6b.jpg';
import kicsi7_b from '../assets/media/images/kicsi/7b.jpg';
import kicsi8_b from '../assets/media/images/kicsi/8b.jpg';
import kicsi9_b from '../assets/media/images/kicsi/9b.jpg';
import kicsi10_b from '../assets/media/images/kicsi/10b.jpg';
import kicsi11_b from '../assets/media/images/kicsi/11b.jpg';
import kicsi12_b from '../assets/media/images/kicsi/12b.jpg';
import kicsi13_b from '../assets/media/images/kicsi/13b.jpg';
import kicsi14_b from '../assets/media/images/kicsi/14b.jpg';






export const Fotovalogatas = {         
                                withOne: [
                                    {
                                        allo: allo1,
                                        fekvo: fekvo1,
                                        bal: kicsi1_a,
                                        jobb: kicsi1_b
                                    },
                                    {
                                        allo: allo2,
                                        fekvo: fekvo2,
                                        bal: kicsi2_a,
                                        jobb: kicsi2_b
                                    },
                                    {
                                        allo: allo3,
                                        fekvo: fekvo3,
                                        bal: kicsi3_a,
                                        jobb: kicsi3_b
                                    },
                                    {
                                        allo: allo4,
                                        fekvo: fekvo4,
                                        bal: kicsi4_a,
                                        jobb: kicsi4_b
                                    },
                                    {
                                        allo: allo5,
                                        fekvo: fekvo5,
                                        bal: kicsi5_a,
                                        jobb: kicsi5_b
                                    },
                                    {
                                        allo: allo6,
                                        fekvo: fekvo6,
                                        bal: kicsi6_a,
                                        jobb: kicsi6_b
                                    },
                                    {
                                        allo: allo7,
                                        fekvo: fekvo7,
                                        bal: kicsi7_a,
                                        jobb: kicsi7_b
                                    },
                                    {
                                        allo: allo8,
                                        fekvo: fekvo8,
                                        bal: kicsi8_a,
                                        jobb: kicsi8_b
                                    },
                                    {
                                        allo: allo9,
                                        fekvo: fekvo9,
                                        bal: kicsi9_a,
                                        jobb: kicsi9_b
                                    },
                                    {
                                        allo: allo10,
                                        fekvo: fekvo10,
                                        bal: kicsi10_a,
                                        jobb: kicsi10_b
                                    },
                                    {
                                        allo: allo11,
                                        fekvo: fekvo11,
                                        bal: kicsi11_a,
                                        jobb: kicsi11_b
                                    },
                                    {
                                        allo: allo12,
                                        fekvo: fekvo12,
                                        bal: kicsi12_a,
                                        jobb: kicsi12_b
                                    }, 
                                    {
                                        allo: allo13,
                                        fekvo: fekvo13,
                                        bal: kicsi13_a,
                                        jobb: kicsi13_b
                                    },
                                    {
                                        allo: allo14,
                                        fekvo: fekvo14,
                                        bal: kicsi14_a,
                                        jobb: kicsi14_b
                                    }
                                ],
                                withTwo: [
                                    {
                                        allo: allo1,
                                        fekvo: fekvo1,
                                        bal: kicsi1_a,
                                        jobb: kicsi1_b,
                                        allo_2: allo2,
                                        fekvo_2: fekvo2,
                                        bal_2: kicsi2_a,
                                        jobb_2: kicsi2_b
                                    },
                                    {
                                        allo: allo3,
                                        fekvo: fekvo3,
                                        bal: kicsi3_a,
                                        jobb: kicsi3_b,
                                        allo_2: allo4,
                                        fekvo_2: fekvo4,
                                        bal_2: kicsi4_a,
                                        jobb_2: kicsi4_b
                                    },
                                    {
                                        allo: allo5,
                                        fekvo: fekvo5,
                                        bal: kicsi5_a,
                                        jobb: kicsi5_b,
                                        allo_2: allo6,
                                        fekvo_2: fekvo6,
                                        bal_2: kicsi6_a,
                                        jobb_2: kicsi6_b
                                    },
                                    {
                                        allo: allo7,
                                        fekvo: fekvo7,
                                        bal: kicsi7_a,
                                        jobb: kicsi7_b,
                                        allo_2: allo8,
                                        fekvo_2: fekvo8,
                                        bal_2: kicsi8_a,
                                        jobb_2: kicsi8_b
                                    },
                                    {
                                        allo: allo9,
                                        fekvo: fekvo9,
                                        bal: kicsi9_a,
                                        jobb: kicsi9_b,
                                        allo_2: allo10,
                                        fekvo_2: fekvo10,
                                        bal_2: kicsi10_a,
                                        jobb_2: kicsi10_b
                                    },
                                    {
                                        allo: allo11,
                                        fekvo: fekvo11,
                                        bal: kicsi11_a,
                                        jobb: kicsi11_b,
                                        allo_2: allo12,
                                        fekvo_2: fekvo12,
                                        bal_2: kicsi12_a,
                                        jobb_2: kicsi12_b
                                    },
                                    {
                                        allo: allo13,
                                        fekvo: fekvo13,
                                        bal: kicsi13_a,
                                        jobb: kicsi13_b,
                                        allo_2: allo14,
                                        fekvo_2: fekvo14,
                                        bal_2: kicsi14_a,
                                        jobb_2: kicsi14_b
                                    }
                                ],
                                withThree: [
                                    {
                                        allo: allo1,
                                        fekvo: fekvo1,
                                        bal: kicsi1_a,
                                        jobb: kicsi1_b,
                                        allo_2: allo2,
                                        fekvo_2: fekvo2,
                                        bal_2: kicsi2_a,
                                        jobb_2: kicsi2_b,
                                        allo_3: allo3,
                                        fekvo_3: fekvo3,
                                        bal_3: kicsi3_a,
                                        jobb_3: kicsi3_b
                                    },
                                    {
                                        allo: allo4,
                                        fekvo: fekvo4,
                                        bal: kicsi4_a,
                                        jobb: kicsi4_b,
                                        allo_2: allo5,
                                        fekvo_2: fekvo5,
                                        bal_2: kicsi5_a,
                                        jobb_2: kicsi5_b,
                                        allo_3: allo6,
                                        fekvo_3: fekvo6,
                                        bal_3: kicsi6_a,
                                        jobb_3: kicsi6_b
                                    },
                                    {
                                        allo: allo7,
                                        fekvo: fekvo7,
                                        bal: kicsi7_a,
                                        jobb: kicsi7_b,
                                        allo_2: allo8,
                                        fekvo_2: fekvo8,
                                        bal_2: kicsi8_a,
                                        jobb_2: kicsi8_b,
                                        allo_3: allo9,
                                        fekvo_3: fekvo9,
                                        bal_3: kicsi9_a,
                                        jobb_3: kicsi9_b
                                    },
                                    {
                                        allo: allo10,
                                        fekvo: fekvo10,
                                        bal: kicsi10_a,
                                        jobb: kicsi10_b,
                                        allo_2: allo11,
                                        fekvo_2: fekvo11,
                                        bal_2: kicsi11_a,
                                        jobb_2: kicsi11_b,
                                        allo_3: allo12,
                                        fekvo_3: fekvo12,
                                        bal_3: kicsi12_a,
                                        jobb_3: kicsi12_b
                                    },
                                    {
                                        allo: allo13,
                                        fekvo: fekvo13,
                                        bal: kicsi13_a,
                                        jobb: kicsi13_b,
                                        allo_2: allo14,
                                        fekvo_2: fekvo14,
                                        bal_2: kicsi14_a,
                                        jobb_2: kicsi14_b,
                                        allo_3: allo9,
                                        fekvo_3: fekvo9,
                                        bal_3: kicsi9_a,
                                        jobb_3: kicsi9_b
                                    }
                                ]
                             }
                
export const FotovalogatasRespo = {         
respoImages: [
                kicsi1_a, kicsi1_b, kicsi2_a, kicsi2_b, kicsi3_a, kicsi3_b, kicsi4_a, kicsi4_b, kicsi5_a, kicsi5_b, kicsi6_a, kicsi6_b, kicsi7_a, kicsi7_b, kicsi8_a, kicsi8_b, kicsi9_a,
                kicsi9_b, kicsi10_a, kicsi10_b, kicsi11_a, kicsi11_b, kicsi12_a, kicsi12_b, kicsi13_a, kicsi13_b, kicsi14_a, kicsi14_b ]}

   