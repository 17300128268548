import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Unity, { UnityContext } from 'react-unity-webgl';
import { MDBContainer } from 'mdb-react-ui-kit';

class DesignerPage extends React.Component {
  constructor(props) {
    super(props);
    let obj;
    try {
      obj = JSON.parse(this.props.jewellery);
    } catch (e) {
      obj = { 'type': 'N/A' };
    }
    this.state = {unityText: 'nincs', jewellery: obj};
    let buildName = 'myensign-test-20220608';
    let buildUrl = "Build-20220608";
    let loaderUrl = buildUrl + "/"+buildName+".loader.js";
    this.unityContext = new UnityContext({
      loaderUrl: loaderUrl,
      dataUrl: buildUrl + "/"+buildName+".data",
      frameworkUrl: buildUrl + "/"+buildName+".framework.js",
      codeUrl: buildUrl + "/"+buildName+".wasm",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "DefaultCompany",
      productName: "WebTest",
      productVersion: "1.0"
    });
  }

  // Unity to React
  componentDidMount() {
      this.unityContext.on("HelloString", this.fromUnity);
      this.unityContext.on("progress", this.onProgress);
  }

  fromUnity = (str) => {
    this.setState({unityText: str});
  }

  componentWillUnmount() {
    this.unityContext.removeAllEventListeners();
  }

  // React to Unity
  buttonClick = () => {
    //alert('buttonClick');
    this.unityContext.send("InputField", "SetTextWithoutNotify", "hellohellohellohellohellohellohellohello");
  }

  onProgress = (progression) => {
    if (progression === 1) {
      setTimeout(() => this.unityContext.send("InputField", "SetTextWithoutNotify", JSON.stringify(this.state.jewellery)), 100);
    }
  }

  render() {

    const { t } = this.props;
    return <MDBContainer><h1>DesignerPage for {this.state.jewellery.type}</h1>
      <div className="unity-container">
        {/* The loading screen will be displayed here. */}
          <div className="loading-overlay">
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: 23 + "%" }}
              />
            </div>
          </div>
        {/* The Unity app will be rendered here. */}
        <Unity className="unity-canvas" unityContext={this.unityContext}/>
      </div>
      <span>Unity Text: {this.state.unityText}</span><br/>
      <button onClick={this.buttonClick}>Click</button>
    </MDBContainer>

  }
}

function DesignerPageFunc() {
  const [searchParams] = useSearchParams();

  return (<DesignerPage jewellery={searchParams.get('jewellery')} />);
}

export default withTranslation()(DesignerPageFunc);
