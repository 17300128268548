import React, { Component } from 'react';
import i18n from '../i18n';
import {
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

import flag_1 from '../assets/media/flags/1.png'
import flag_2 from '../assets/media/flags/2.png'
import flag_3 from '../assets/media/flags/3.png'
import flag_4 from '../assets/media/flags/4.png'
import flag_5 from '../assets/media/flags/5.png'
import flag_6 from '../assets/media/flags/6.png'
import flag_7 from '../assets/media/flags/7.png'
import flag_8 from '../assets/media/flags/8.png'
import flag_9 from '../assets/media/flags/9.png'
import flag_10 from '../assets/media/flags/10.png'
import flag_11 from '../assets/media/flags/11.png'
import flag_12 from '../assets/media/flags/12.png'
import flag_13 from '../assets/media/flags/13.png'
import flag_14 from '../assets/media/flags/14.png'
import flag_15 from '../assets/media/flags/15.png'
import flag_16 from '../assets/media/flags/16.png'
import flag_17 from '../assets/media/flags/17.png'
import flag_18 from '../assets/media/flags/18.png'
import flag_19 from '../assets/media/flags/19.png'
import flag_20 from '../assets/media/flags/20.png'
import flag_21 from '../assets/media/flags/21.png'
import flag_22 from '../assets/media/flags/22.png'
import flag_23 from '../assets/media/flags/23.png'
import flag_24 from '../assets/media/flags/24.png'
import flag_25 from '../assets/media/flags/25.png'
import flag_26 from '../assets/media/flags/26.png'
import flag_zero from '../assets/media/flags/zero.png'
import flag_one from '../assets/media/flags/one.png'
import flag_two from '../assets/media/flags/two.png'
import flag_three from '../assets/media/flags/three.png'
import flag_four from '../assets/media/flags/four.png'
import flag_five from '../assets/media/flags/five.png'
import flag_six from '../assets/media/flags/six.png'
import flag_seven from '../assets/media/flags/seven.png'
import flag_eight from '../assets/media/flags/eight.png'
import flag_nine from '../assets/media/flags/nine.png'


const flagArray = [{flag: flag_1, text: "ALFA"}, {flag: flag_2, text: "BRAVO"}, {flag: flag_3, text: "CHARLIE"}, {flag: flag_4, text: "DELTA"}, {flag: flag_5, text: "ECHO"}, {flag: flag_6, text: "FOXTROT"},
                   {flag: flag_7, text: "GOLF"}, {flag: flag_8, text: "HOTEL"}, {flag: flag_9, text: "INDIA"}, {flag: flag_10, text: "JULIET"}, {flag: flag_11, text: "KILO"}, {flag: flag_12, text: "LIMA"},
                   {flag: flag_13, text: "MIKE"}, {flag: flag_14, text: "NOVEMBER"}, {flag: flag_15, text: "OSCAR"}, {flag: flag_16, text: "PAPA"}, {flag: flag_17, text: "QUEBEC"}, {flag: flag_18, text: "ROMEO"},
                   {flag: flag_19, text: "SIERRA"}, {flag: flag_20, text: "TANGO"}, {flag: flag_21, text: "UNIFORM"}, {flag: flag_22, text: "VICTOR"}, {flag: flag_23, text: "WHISKEY"}, {flag: flag_24, text: "XRAY"},
                   {flag: flag_25, text: "YANKEE"}, {flag: flag_26, text: "ZULU"}, {flag: flag_zero, text: "0"}, {flag: flag_one, text: "1"}, {flag: flag_two, text: "2"}, {flag: flag_three, text: "3"},
                   {flag: flag_four, text: "4"}, {flag: flag_five, text: "5"}, {flag: flag_six, text: "6"}, {flag: flag_seven, text: "7"}, {flag: flag_eight, text: "8"}, {flag: flag_nine, text: "9"},]
class NauticalFlags extends Component {

  render() {
    let flags = flagArray.map((k,n) => {return(
      <MDBCol md="6" key={n} className="py-4 ps-5">
        <img src={k.flag} alt={"flag_" + n} style={{width: "35%"}}/>
        <span className="ms-3">{k.text}</span>
      </MDBCol>
    )})
    return (
      <>
      <MDBRow className="m-0 ps-2">
        {flags}
      </MDBRow>
      </>
    );
  }
}

export default NauticalFlags;
