import React from 'react';
import { withTranslation } from 'react-i18next';
import { MDBContainer } from 'mdb-react-ui-kit';
import { getAllBasketItems } from '../components/db/basket';
import { initCustomerInfo, getCustomerInfo } from '../components/db/customer';

class BasketPage extends React.Component {

  state = {
    "basketItems": [],
    "customer": {}
  };

  constructor(props) {
    super(props);
    initCustomerInfo();
  }

  componentDidMount() {
    this.loadBasket();
    this.loadCustomerInfo();
  }

  loadBasket = () => {
    let self = this;
    getAllBasketItems().then((basketContent) => {
      self.setState({"basketItems": basketContent});
    });
  }

  loadCustomerInfo = () => {
    let self = this;
    getCustomerInfo().then((customer) => {
      self.setState({"customer": customer});
    });
  }

  render() {
    const { t } = this.props;
    if (this.state.basketItems === undefined || this.state.customer === undefined) {
      return <MDBContainer><h1>Basket</h1>Your basket is empty.</MDBContainer>;
    } else {
      let basketItems = this.state.basketItems.map((value, index) => <div key={index}>
            {value.count}x <img src={value.picture} width={30} /><b>{value.type} - {value.preciousMetal}</b>, {value.weight} g, € {value.price}<br/>
          </div>);
      
      return <MDBContainer><h1>Order</h1>
        {basketItems}
        {this.state.customer.email} {this.state.customer.phone}
      </MDBContainer>
    }
  }
}


export default withTranslation()(BasketPage);
