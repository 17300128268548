import React, { useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { StripeReturnUrl } from '../data/loginkeys';



const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    //setOutWarning(false)
    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: StripeReturnUrl.url,
      },
    });


    if (error) {
      setErrorMessage(error.message);
    } else {
    }
  };
 
  return (
    <>
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="d-flex justify-content-center align-items-center py-3">
      <button type="submit" className="pay-button" disabled={!stripe}>Pay</button></div>
      
      {errorMessage && <div>{errorMessage}</div>}
    </form>
    
    </>
    
  )
};

export default CheckoutForm;
