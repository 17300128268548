import React from 'react';
import { withTranslation } from 'react-i18next';
import { MDBContainer } from 'mdb-react-ui-kit';
import Basket from '../components/Basket';
import CustomerEditor from '../components/CustomerEditor';
import InterB2BAxios from '../Service/InterB2BAxios';
import { getCurrency, getCustomerInfo } from '../components/db/customer';
import { getAllBasketItems } from '../components/db/basket';

class BasketPage extends React.Component {

  constructor(props) {
    super(props);
    this.interAxios = new InterB2BAxios(this, 'BasketPage');
  }

  getAllData = async () => {
    let customer = await getCustomerInfo();
    let basketItems = await getAllBasketItems();
    let currency = await getCurrency();
    let orderDTO = {
      customer: customer,
      basket: basketItems,
      currency: currency
    };
    console.log("orderDTO: " + JSON.stringify(orderDTO));
    return orderDTO;
  }

  order = () => {
    let self = this;
    this.getAllData()
    .then((orderDTO) => {
      self.interAxios.post("/order", orderDTO, function(response) {
        console.log("Order sent.");
      });
    });
  }

  render() {
      return <MDBContainer>
        <Basket/>
        <CustomerEditor />
        <button onClick={this.order}>Order</button>
      </MDBContainer>
    }
}

export default withTranslation()(BasketPage);
