import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const languageDetector = new LanguageDetector();
/*const domainEndingDetector = {
  name: 'domainEndingDetector',

  lookup(options) {
    //console.log('EEE');
    if (typeof window !== 'undefined') {
      let url = window.location.href;
      let serverRegexp = new RegExp('(?:http[s]*\:\/\/(?:www\.interb2bike|e\-bikeboerse))\.([a-z]*).*');
      let serverMatch = serverRegexp.exec(url);
      if (serverMatch !== null) {
        //console.log('m1:' + serverMatch[1]);
        let tld = serverMatch[1];
        if (tld === 'de') {
          return 'de';
        } else if (tld === 'com') {
          return 'en';
        }
      }
      return undefined;
    }
  }
};*/
//languageDetector.addDetector(domainEndingDetector);

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      order: ['cookie', 'localStorage', 'domainEndingDetector', 'sessionStorage', 'navigator']
    },
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
