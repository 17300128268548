import React from 'react';
import { Outlet } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styles from '../assets/scss/modules/pagetemplate.module.scss';


class PageTemplate extends React.Component {
	constructor(props) {
    super(props);
    this.state = {showModal: false
									};
  };

	showModal = (text) => {
		this.setState({showModal: true, text: text})
	}
	closeModal = (text) => {
		this.setState({showModal: false})
	}
  render() {
		const { t } = this.props;
    return (<>
        <div className={styles.bodywrapper}>
          <Outlet/>

        </div>

				</>
    );
  }
}

export default withTranslation()(PageTemplate);
