import React from 'react';

class CurrencySelector extends React.Component {

    eur = () => {
        this.props.onChange('EUR');
    }

    usd = () => {
        this.props.onChange('USD');
    }

    huf = () => {
        this.props.onChange('HUF');
    }

    render() {
        return (
            <div className="myensign-tabs ">
                {this.props.currency === 'EUR' ?
                <div className="myensign-tab active">EUR</div> : 
                <div className="myensign-tab" onClick={this.eur}>EUR</div>}
                
                {/*this.props.currency == 'USD' ?
                <div className="myensign-tab active">USD</div> : 
                <div className="myensign-tab" onClick={this.usd}>USD</div>*/}
                
                {this.props.currency === 'HUF' ?
                <div className="myensign-tab active">HUF</div> : 
                <div className="myensign-tab" onClick={this.huf}>HUF</div>}
            </div>
        ) ;
    }
}

export default CurrencySelector;