//import logo from './logo.svg';
//import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import DesignerPage from './pages/DesignerPage';
import BasketPage from './pages/BasketPage';
import OrderPage from './pages/OrderPage';
import PageTemplate from './pages/PageTemplate';
import DummyPayPage from './pages/DummyPayPage';
import PayPageOngoing from './pages/PayPageOngoing';

//export const baseUrl = "/test";
//"homepage": "/test",
export const baseUrl = "";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={baseUrl+"/"} element={<MainPage {...this} {...this.state}/>}>
            <Route path={baseUrl+"/"} element={<MainPage {...this} {...this.state}/>}/>
            <Route path={baseUrl+"/DesignerPage"} element={<DesignerPage {...this} {...this.state}/>}/>
          </Route>
          <Route path={baseUrl+"/BasketPage"} element={<BasketPage {...this} {...this.state}/>}/>
          <Route path={baseUrl+"/OrderPage"} element={<OrderPage {...this} {...this.state}/>}/>
          <Route path={baseUrl+"/DummyPayPage"} element={<DummyPayPage {...this} {...this.state}/>}/>
          <Route path={baseUrl+"/PayPageOngoing"} element={<PayPageOngoing {...this} {...this.state}/>}/>
        </Routes>
        </BrowserRouter>
    );
  }
}

export default App;
