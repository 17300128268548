import React, { Component } from "react";

import InterB2BAxios from '../Service/InterB2BAxios';




class DummyPayPage extends Component {

    constructor(props) {
        super(props);
        this.interAxios = new InterB2BAxios(this);
    }

    componentDidMount() {
        let self = this;
		this.interAxios.post('/order', {
            "customer": {
                "email": "pelda@example.com",
                "phone": "+36-30-1234567",
                "billing": {
                    "name": "Gipsz Jakab",
                    "postcode": 1234,
                    "country": "HU",
                    "city": "Budapest",
                    "address": "Fő út 1.",
                    "note": "Ne ide hozzák, la!"
                },
                "shipping": {
                    "name": "Jakob Gips",
                    "postcode": 80000,
                    "country": "DE",
                    "city": "München",
                    "address": "Haupstrasse 1",
                    "note": "Ide hozzák, la!"
                }
            },
            "basket": [
            {
                "basketId": 1,
                "type": "Bracelet",
                "variant": "3",
                "size": 20,
                "color": "brown",
                "preciousMetal": "rose gold",
                "characters": [
                    "ABCDEFG",
                    "ABCDEFG",
                    "ABCDEFG"
                ],
                "weight": 7.02,
                "price": 1140
            },
            {
                    "basketId": 2,
                "type": "Bracelet",
                "variant": "3",
                "size": 20,
                "color": "brown",
                "preciousMetal": "rose gold",
                "characters": [
                    "ABCDEFG",
                    "ABCDEFG",
                    "ABCDEFG"
                ],
                "weight": 7.02,
                "price": 900
            }
            ],
            "currency": "USD"
        }
        , function(response) {
            const {paymentUrl, paymentIntentId } = response.data;
			localStorage.setItem("paymentIntentId", paymentIntentId);
            window.location.href=paymentUrl;
		});
    }

    render() {
        return <div>Payment is starting...</div>
    }

}

export default DummyPayPage;