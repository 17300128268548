import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TermsAndConditions from '../../About/TermsAndConditions';
import LegalStatement from '../../About/LegalStatement';
import DataProtection from '../../About/DataProtection';
import ShipingAndBillingData from '../../About/ShipingAndBillingData';
import Contact from '../../About/Contact';
import Partners from '../../About/Partners';
import NauticalFlags from '../../About/NauticalFlags';
import ProductInformation from '../../About/ProductInformation';
import Basket from '../Basket';
import ReturnPage from '../../stripe/ReturnPage';
import myensign_logo from '../../assets/media/images/MYENSIGN_LOGO_VECTOR_ kék.png';
import close from '../../assets/media/icons/close.svg';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody
} from 'mdb-react-ui-kit';

import i18n from '../../i18n';

class FooterModal extends Component {

  toggle = nr => () => {
    let modalNumber = 'modal' + nr
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  render() {
    const { t } = i18n;
    return (
      <MDBContainer>
        <MDBModal show={this.props.show} animationDirection='right' closeOnEsc={false} onHidePrevented={() => this.props.handleClose()} staticBackdrop>
        <MDBModalDialog position='top-right' side scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              {this.props.text !== t('Basket') ? <>
                <MDBModalTitle>{this.props.text}</MDBModalTitle>
                <MDBBtn className='btn-close'  onClick={this.props.handleClose} tabindex="-1">x</MDBBtn>
              </>
              : <>
                  <div className="d-flex align-items-center justify-content-center mt-4" style={{width: "100%"}}><img alt="" className="myensign-logo-vector-basket" src={myensign_logo}></img></div>
                  <MDBBtn className='btn-close basket'  onClick={this.props.handleClose} tabindex="-1"><img src={close}  alt="" ></img></MDBBtn>
              </>
              }
                  
            </MDBModalHeader>
          <MDBModalBody>
         
          <div>
            {this.props.text === t('Terms_and_conditions') ?
            <TermsAndConditions/>:false}
            {this.props.text === t('Legal_statement') ?
            <LegalStatement/>:false}
            {this.props.text === t('Data_processing_statement') ?
            <DataProtection/>:false}
            {this.props.text === t('Shipping_and_billing_data') ?
            <ShipingAndBillingData/>:false}
            {this.props.text === t('Contact') ?
            <Contact/>:false}
            {this.props.text === t('Nautical_flag_codes') ?
            <NauticalFlags/>:false}
            {this.props.text === t('Partners') ?
            <Partners/>:false}
            {this.props.text === t('Product_information') ?
            <ProductInformation/>:false}
            {this.props.text === t('Basket') ?
            <Basket jewellery={this.props.jewellery} handleClose={this.props.handleClose} showModal={this.props.showModal}/>:false}
            {this.props.text === t('Success') ?
            <ReturnPage handleClose={this.props.handleClose}/>:false}
            {this.props.text === t('A_successful_shopping') ?
            <ReturnPage />:false}
          </div>
          
          </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default withTranslation()(FooterModal);
