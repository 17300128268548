// react imports
import React, { useEffect } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProductKarte from './ProductKarte';
const CarouselComponentJewellery = (props) => {

  //const context = useContext(AppContext);

  // STATES
  


    
    

  const responsive = {
    superWidthDesktop: {
        breakpoint: { max: 4000, min: 3040 },
        items: 8,
        partialVisibilityGutter: 10
      },
    desktop: {
      breakpoint: { max: 3039, min: 2670 },
      items: 7,
      partialVisibilityGutter: 10
    },
    desktop_small: {
      breakpoint: { max: 2669, min: 2290 },
      items: 6,
      partialVisibilityGutter: 10
    },
    desktop_small_2: {
      breakpoint: { max: 2289, min: 1920 },
      items: 5,
      partialVisibilityGutter: 10
    },
    tablet_width: {
        breakpoint: { max: 1919, min: 1480 },
        items: 4,
        partialVisibilityGutter: 6
    },
    small: {
        breakpoint: { max: 1479, min: 1090 },
        items: 3,
        partialVisibilityGutter: 1
    },
    small_2: {
      breakpoint: { max: 1089, min: 730 },
      items: 2,
      partialVisibilityGutter: 1
  },
  small_3: {
    breakpoint: { max: 729, min: 0 },
    items: 1,
    partialVisibilityGutter: 1
}
  };




  useEffect(() => {
     
  }, []);

  

  return (
    
    <div>
            <Carousel
                additionalTransfrom={0} 
                draggable={false}
                showDots={false}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={false}
                
                centerMode={false}
                keyBoardControl={false}
                customTransition="transform 600ms ease-in-out"
                transitionDuration={600}
                slidesToSlide={1}
                containerClass="carousel-container"
                autoPlay={false}
                autoPlaySpeed={11999}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
               
            >
            
            {props.jewellerys.map(
			(msg, n) => {
					return (
						<div key={n+900} className="px-3 py-0">
							<ProductKarte
								onDesignerComponent = {(jewellery) => props.onDesignerComponent(jewellery)} 
                                showBasketModal = {props.showBasketModal}
                                onScrollToDesign = {props.onScrollToDesign}
								msg={msg}
								number={n+1}
               
							/>
						</div>
									);
		        })}
            </Carousel>
           
    </div>


  );
};

export default CarouselComponentJewellery;
