import React, { Component } from 'react';
import i18n from '../i18n';
import aranyvilag from '../assets/media/images/aranyvilag.png'
import kocsisekszer from '../assets/media/images/kocsisekszer.png'
import miracolo from '../assets/media/images/MiracoloLogo2018_001.png'


import {
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';

class Partners extends Component {

  render() {
    return (<>
    <MDBRow className="pt-1 pb-3">
      <div>
      <img src={aranyvilag} style={{height: "30px", marginRight: "15px"}}/>
      <strong className="font-weight-bold">{i18n.t('Aranyvirag_Jewellery_Salon')}</strong>
      </div>
      <div>
      H-4024 Debrecen Csapó u. 43.
      </div>
      <div>
      <a href={"mailto:www.aranyviragekszer.hu"}>www.aranyviragekszer.hu</a>
      </div>
    </MDBRow>
    <MDBRow className="pb-3">
      <div>
      <img src={kocsisekszer} style={{height: "30px", marginRight: "15px"}}/>
      <strong className="font-weight-bold">{i18n.t('Kocsis_Jewellery_Store')}</strong>
      </div>
      <div>
      H-1171 Budapest Szabadság sugárút 37.
      </div>
      <div>
      <a href={"mailto:www.kocsisekszer.com"}>www.kocsisekszer.com</a>
      </div>
    </MDBRow>
    <MDBRow className="pb-3">
      <div>
      <img src={miracolo} style={{height: "30px", marginRight: "15px"}}/>
      <strong className="font-weight-bold">Miracolo Óra-Ékszer Allee</strong>
      </div>
      <div>
      H-1117 Budapest Október huszonharmadika u. 8-10.
      </div>
      <div>
      <a href={"mailto:www.miracolo.hu"}>www.miracolo.hu</a>
      </div>
    </MDBRow>
    <MDBRow className="pb-3">
      <div>
      <img src={miracolo} style={{height: "30px", marginRight: "15px"}}/>
      <strong className="font-weight-bold">Miracolo Óra-Ékszer Árkád</strong>
      </div>
      <div>
      H-1148 Budapest Örs vezér tere 25.
      </div>
      <div>
      <a href={"mailto:www.miracolo.hu"}>www.miracolo.hu</a>
      </div>
    </MDBRow>
    </>
    );
  }
}

export default Partners;
