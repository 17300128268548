import React, { Component, useState } from "react";
import LanguageComponent from './LanguageComponent';
import facebook from '../assets/media/images/facebook_icn_1.png';
import instagram from '../assets/media/images/instagram_icn _1.png';
import twitter from '../assets/media/images/twitter_icn_1.png';
import busket_icon from '../assets/media/images/Group 13_gray.png';
import myensign_logo from '../assets/media/images/MYENSIGN_LOGO_VECTOR_ kék.svg';
import FooterModal from './Modals/FooterModal';
import i18n from '../i18n';
import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBNavbar,
	MDBNavbarItem,
	MDBNavbarLink,
  	MDBBtn,
	MDBBadge,
	MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
	MDBIcon
} from 'mdb-react-ui-kit';

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };


  render() {
	const { t } = i18n;
    return (
		
  		<div className="navbarcomponent-header-footer" style={{backgroundColor: this.props.isBasket ? "#f6f6f6" : "#EFEFF4"}}>
		
        <MDBNavbar className="navbarcomponent-header-footer-nav d-none d-lg-block">
		<MDBContainer className="p-0"  style={{maxWidth: "1650px"}}>
          <MDBRow end className="m-0 pl-4" style={{width: "100%"}}>
						<MDBCol center md="2" className="pl-5">
							<img alt="" className="myensign-logo-vector-kek" src={myensign_logo}></img>
						{/*!this.props.isBasket ?
							<span className="myensign-all-right">© Myensign all rights reserved</span>:false*/}
						</MDBCol>
						<MDBCol center md="7" className="p-0">
							<MDBRow center className="m-0 px-3 d-flex">
								<MDBNavbarItem onClick={() => this.props.showModal(t('Nautical_flag_codes'))}>
									<MDBNavbarLink className="text-link flag" >{t('Nautical_flag_codes')}</MDBNavbarLink>
								</MDBNavbarItem>
								<MDBNavbarItem onClick={() => this.props.showModal(t('Product_information'))}>
									<MDBNavbarLink className="text-link product"  >{t('Product_information')}</MDBNavbarLink>
								</MDBNavbarItem>
								<MDBNavbarItem onClick={() => this.props.showModal(t('Contact'))}>
									<MDBNavbarLink  className="text-link contact" >{t('Contact')}</MDBNavbarLink>
								</MDBNavbarItem>
								<MDBNavbarItem onClick={() => this.props.showModal(t('Partners'))}>
									<MDBNavbarLink className="text-link partners"  >{t('Partners')}</MDBNavbarLink>
								</MDBNavbarItem>
							</MDBRow>
						</MDBCol>

						<MDBCol center md="3" className="p-0">
							<MDBRow end className="m-0 p-0">
								<MDBCol center md="7" xl="7" className="p-0 d-flex justify-content-end align-items-center">
									<MDBNavbarItem>
										<a href="https://www.instagram.com/accounts/login/?next=/myensign/" target="_blank" rel="noopener noreferrer">
											<img alt="" className="instagram-logo" src={instagram}></img>
										</a>
									</MDBNavbarItem>
									<MDBNavbarItem>
										<a href="https://twitter.com/MyEnsign" target="_blank" rel="noopener noreferrer">
											<img alt="" className="twitter-logo" src={twitter}></img>
										</a>
									</MDBNavbarItem>
									<MDBNavbarItem>
										<a href="https://www.facebook.com/myensign/" target="_blank" rel="noopener noreferrer">
											<img alt="" className="facebook-logo" src={facebook}></img>
										</a>
									</MDBNavbarItem>
								</MDBCol>
								<MDBCol md={this.props.isBasket ? "3" : "5"} xl={this.props.isBasket ? "3" : "5"} className={this.props.isBasket ? "p-0  d-flex justify-content-end align-items-center" : "p-0  d-flex justify-content-end align-items-ende"} style={{width: "68.54px"}}>
									<MDBRow center className="m-0">
										<LanguageComponent/>
									</MDBRow>
								</MDBCol>
								{this.props.isBasket ? <>
								<MDBCol md="2"  xl="2" className="p-0 d-flex justify-content-end align-items-center">
									<button type="button" onClick={this.props.showBasketModal}>
										<img className="busket-icon" alt="" src={busket_icon}/>
										{window.localStorage.getItem('count') > 0 && (<MDBBadge pill className="busket-badge">{window.localStorage.getItem('count')}</MDBBadge>)}
										
									</button>
								</MDBCol>
								</>:false}
							</MDBRow>
						</MDBCol>

          </MDBRow>
		  </MDBContainer>
		  {this.state.showModal ?
			<FooterModal
				show= {this.state.showModal}
				handleClose = {this.closeModal}
				text= {this.state.text}
			/>:false}
        </MDBNavbar>
		<div className=" sidenav-bottom-respo d-block d-lg-none">
            <div className=" side-navbar-component d-flex align-items-center" style={{height: "75px"}}>
				<img src={myensign_logo} height='45' alt="" className="sidenav-logo"/>
				<div className="respo-language">		
					<LanguageComponent/>
				</div>
                <MDBBtn onClick={() => this.props.setShowBasic(!this.props.showBasic)} className="sidenavButton">
					<MDBIcon fas icon="bars" color="dark" size="3x"/>
                </MDBBtn>
            
            </div> 
            <MDBSideNav isOpen={this.props.showBasic} absolute getOpenState={(e) => this.props.setShowBasic(e)} right>
            <MDBSideNavMenu>
            <MDBRow center className="ms-0 me-4 py-5">
				<MDBCol center md="7" xl="7" size="8" className="p-0 d-flex justify-content-end align-items-center">
				</MDBCol>
				<MDBCol md="2"  xl="2" size="4" className="p-0 d-flex justify-content-end align-items-center">
					<button type="button" onClick={this.props.showBasketModal}>
						<img className="busket-icon" alt="" src={busket_icon}/>
						{window.localStorage.getItem('count') > 0 && (<MDBBadge pill className="busket-badge">{window.localStorage.getItem('count')}</MDBBadge>)}
						
					</button>
				</MDBCol>
				
			</MDBRow>
            <div className="side-menu-list">
              <div style={{width: "100%"}}>
              	<MDBSideNavItem onClick={() => this.props.showModal(t('Nautical_flag_codes'))}>
					<MDBSideNavLink className="text-center flag" >{t('Nautical_flag_codes')}</MDBSideNavLink>
				</MDBSideNavItem>
				<MDBSideNavItem onClick={() => this.props.showModal(t('Product_information'))}>
					<MDBSideNavLink className="text-link product"  >{t('Product_information')}</MDBSideNavLink>
				</MDBSideNavItem>
				<MDBSideNavItem onClick={() => this.props.showModal(t('Contact'))}>
					<MDBSideNavLink  className="text-link contact" >{t('Contact')}</MDBSideNavLink>
				</MDBSideNavItem>
				<MDBSideNavItem onClick={() => this.props.showModal(t('Partners'))}>
					<MDBSideNavLink className="text-link partners"  >{t('Partners')}</MDBSideNavLink>
				</MDBSideNavItem>
              </div>
            </div>
			<MDBRow end className="m-0 pt-5">
				<MDBCol center md="7" xl="7" size="12" className="p-0 d-flex justify-content-center align-items-center">
					<MDBSideNavItem>
						<a href="https://www.instagram.com/accounts/login/?next=/myensign/" target="_blank" rel="noopener noreferrer">
							<img alt="" className="instagram-logo" src={instagram}></img>
						</a>
					</MDBSideNavItem>
					<MDBSideNavItem>
						<a href="https://twitter.com/MyEnsign" target="_blank" rel="noopener noreferrer">
							<img alt="" className="twitter-logo" src={twitter}></img>
						</a>
					</MDBSideNavItem>
					<MDBSideNavItem>
						<a href="https://www.facebook.com/myensign/" target="_blank" rel="noopener noreferrer">
							<img alt="" className="facebook-logo" src={facebook}></img>
						</a>
					</MDBSideNavItem>
				</MDBCol>
				
			</MDBRow>
            </MDBSideNavMenu>
            </MDBSideNav>
        </div>
      </div>
	  
    );
  }
}

export default function NavbarComponentFunc(props) {
    const [showBasic, setShowBasic] = useState(false);

    return <NavbarComponent showBasic={showBasic} setShowBasic={setShowBasic} isBasket={props.isBasket} showModal={props.showModal} showBasketModal={props.showBasketModal}/>;
}
