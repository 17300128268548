import React from 'react';
import { withTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Unity, { UnityContext } from 'react-unity-webgl';
import { MDBContainer } from 'mdb-react-ui-kit';
import { baseUrl } from '../App';
import '../assets/scss/pages/designerpage.scss';
import { addItemToBasket } from '../components/db/basket';

class DesignerComponent extends React.Component {
  state = {
    unityProgress: 0,
    shutDown: false,
  }
  constructor(props) {
    super(props);
    let obj;
    
    try {
      this.state = {};
    } catch (e) {
      
    }
    
    let buildName = 'myensign-creator';
    let buildUrl = "myensign-creator-Build";
    let loaderUrl = baseUrl + "/" + buildUrl + "/"+buildName+".loader.js";
    this.unityContext = new UnityContext({
      loaderUrl: loaderUrl,
      dataUrl: baseUrl + "/" + buildUrl +  "/"+buildName+".data",
      frameworkUrl: baseUrl + "/" + buildUrl + "/"+buildName+".framework.js",
      codeUrl: baseUrl + "/" + buildUrl + "/"+buildName+".wasm",
      streamingAssetsUrl: "StreamingAssets",
      companyName: "HT",
      productName: "Jewel Designer",
      productVersion: "1.2"
    });
  }

  // Unity to React
  componentDidMount() {
      this.unityContext.on("Buy", this.fromUnity);
      this.unityContext.on("progress", this.onProgress);
      this.unityContext.on("UnityLoaded", this.unityLoaded);
      console.log("belép");
  }

  componentDidUpdate(prevProps, prevState) {
   /* if(this.state.jewellery !== this.props.jewellery){
      let obj;
    
    try {
      obj = this.props.jewellery;
    } catch (e) {
      obj = {"type": "Bracelet","picture": "data:image/png;base64,iVBORw0KGgoAA...","variant": "3","size": 20,"color": "red","preciousMetal": "rose gold","collection": "chocolate","characters": [ "DEF$%","hgf","123" ],"weight": 4.13,"price": 634} ;
    }
    this.state = {unityText: 'nincs', jewellery: obj};
    this.unityContext.send("Controller", "LoadData", JSON.stringify(obj))
    }*/

  }

  fromUnity = (str) => {
    const obj = JSON.parse(str);
    
    addItemToBasket(obj)
    this.props.showBasketModal();
    this.unityContext.quitUnityInstance().then(() => this.props.onCancelDesignerComponent());
  }

  componentWillUnmount() {
    this.unityContext.removeAllEventListeners();
    if(this.state.shutDown){
      this.unityContext = null
    }
  }

  onProgress = (progression) => {
    this.setState({unityProgress: progression});
    if (progression === 1) {
      //setTimeout(() => this.unityContext.send("Controller", "LoadData", JSON.stringify(this.state.jewellery)), 100);
    }
  }

  unityLoaded = () => {
    this.setState({shutDown: true});
    console.log(this.props.jewellery);
   
    if(this.props.jewellery === null){
      this.unityContext.send("Controller", "LoadData", "")
    }else{
      let jewellery = {basketId : this.props.jewellery.basketId, type: this.props.jewellery.type, variant: this.props.jewellery.variant, size: this.props.jewellery.size, 
        color: this.props.jewellery.color, preciousMetal: this.props.jewellery.preciousMetal, characters: this.props.jewellery.characters, weight: this.props.jewellery.weight,
         count: this.props.jewellery.count, collection: this.props.jewellery.collection, hufPrice: this.props.jewellery.hufPrice, price: this.props.jewellery.price, usdPrice: this.props.jewellery.usdPrice}
  
      this.unityContext.send("Controller", "LoadData", JSON.stringify(jewellery))
    }
    
  }

  render() {
    const { t } = this.props;
    
    return (
      <MDBContainer className="designer-container">
        
      
      {/*<span className="designer-title">{this.state.jewellery === null ? "Designer" : "DesignerPage for " + this.state.jewellery.type}</span>*/}
      <div className="unity-container">
        {/* The loading screen will be displayed here. */}
          <div className="loading-overlay" style={{display: this.state.unityProgress < 1 ? 'block' : 'none'}}>
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: (this.state.unityProgress * 100) + "%" }}>
                  {(this.state.unityProgress * 100).toFixed(2) + '%'}
              </div>
            </div>
          </div>
        {/* The Unity app will be rendered here. */}
        <Unity className="unity-canvas" unityContext={this.unityContext}/>
      </div>
      
      {/*<span>Unity Text: {this.state.unityText}</span><br/>*/}
    </MDBContainer>
    );

  }
}

function DesignerPageFunc(props) {
  const [searchParams] = useSearchParams();
  return (<DesignerComponent jewellery={props.jewellery !== undefined ? props.jewellery : searchParams.get('jewellery')} 
  showBasketModal= {props.showBasketModal} onCancelDesignerComponent = {props.onCancelDesignerComponent}/>);
}

export default withTranslation()(DesignerPageFunc);
