import React, { Component } from "react";
/*import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import TablePage from './TablePage';
import CreateOrderDTO from './CreateOrderDTO';
import Moment from 'moment';
import Footer from '../../components/Footer/Footer';
import logo from "../../assets/media/logo/1.png";
import ib2bicon from "../../assets/media/images/ib2b.png";
import styles from '../../assets/scss/modules/pages.module.scss';
import arrowcircleleft from "../../assets/media/newicons/arrow-circle-left.png";
import spinner from "../../assets/media/icons/Spinner-1s-200px.gif";
import {
	MDBBtn,
	MDBIcon,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBContainer
} from 'mdb-react-ui-kit';
*/
import spinner from '../assets/media/icons/Spinner-1s-200px.gif';
import WaitingFor from "../components/WaitingFor";
import InterB2BAxios from '../Service/InterB2BAxios';

import { baseUrl } from "../App";



class PayPageOngoing extends Component {

  constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this);
    	this.state = {
			paymentStatus: 'NOT_REQUESTED'
		}
	};

	checkPaymentOngoing = () => {
		let self = this;
		this.interAxios.get('/stripe-payment-status?paymentIntentId='+localStorage.getItem("paymentIntentId"), function(response) {
			self.setState({ paymentStatus: response.data });
		});
	}

	isPaymentFinished = () => {
		return this.state.paymentStatus === 'PAYMENT_ACCEPTED' || this.state.paymentStatus === 'PAYMENT_FAILED';
	}


  render() {
		return <WaitingFor doCheck={this.checkPaymentOngoing}
					   proceedsIf={this.isPaymentFinished}
					   nextPage={baseUrl}>
				<div>
					Waiting for payment to proceed...<img src={spinner} alt=""
					/>
				</div>
		   </WaitingFor>;

/*

		if (this.state.paymentOngoing) {
	    return (
				<div className={styles.bodywrapper}>
					<MDBContainer>
						<MDBRow center>
							<h2 className="font-weight-bold">{t('laufende_zahlung')}</h2>
						</MDBRow>
						<MDBRow center>
							<MDBCard className="price-card final">
								<MDBCardBody className="text-center">
									<h1>{t('warten_sie_bitte_bis_zum_ende_der_zahlung')}</h1>
									<img src={spinner}/>
								</MDBCardBody>
							</MDBCard>
						</MDBRow>
						<Footer homeStatus={false}/>
					</MDBContainer>
				</div>
	    )
		} else {
			return (
				<Redirect to="/MyProfilePageB2B"/>
			);
		}*/
  }
}

export default PayPageOngoing;
